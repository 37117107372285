query getFreeLines($filter: FreeLineFilter) {
    getFreeLines(filter: $filter) {
        freeLines {
            id
            color
            createdBy
            createdDate
            updatedBy
            updatedDate
            location
            type {
                id
                txt
            }
            lineWidth
            state {
                id
                txt
            }
            length
            identification
            description
            plan {
                id
                name
            }
        }
    }
}
