import { FormConfig } from '../types';
import { getFreeAreas } from '@apis/keycore';
import { planGroup } from '../groups';
import { Icons } from '@keypro/2nd-xp';
import { createComboField } from '../field-utils';

const config: FormConfig = {
    gqlType: 'FreeArea',
    model: 'freearea',
    modelAliases: ['area'],
    icon: <Icons.Area />,
    groups: [
        {
            name: 'objectData',
            fields: [
                'name',
                'color',
                { name: 'areaSize', translationKey: 'size' },
                createComboField('type', 'FREEAREA_TYPE', false, 'type'),
                createComboField('state', 'FREEAREA_STATE', false, 'state'),
            ],
        },
        planGroup,
    ],
    excludeFromSearch: ['plan', 'planIncludeCompleted'],
    functions: {
        get: getFreeAreas,
    },
    queryCollection: 'freeAreas',
};

export default config;
