import { RestStringFiltersInput } from '@generated';
import { GraphQLFilter } from '@apis/utils';
import { MapController } from '@keypro/2nd-xp';
import { ReactNode } from 'react';

/**
 * Type of UI component to use with a form field.
 */
export type FieldComponent =
    | 'auto'
    | 'text'
    | 'textarea'
    | 'number'
    | 'combobox'
    | 'combobox-multi'
    | 'radio'
    | 'checkbox'
    | 'date'
    | 'address'
    | 'download'
    | 'objectReference'
    | 'link'
    | 'range-number';

/**
 * Arbitrary form data.
 */
export type FormData = Record<string, unknown>;

/**
 * Function for formatting the field value.
 */
export type ValueFormatter = (
    formData: FormData,
    value: unknown,
) => string | JSX.Element;

/**
 * Function for formatting the combobox option label.
 */
export type LabelFormatter = (optionData: FormData) => string;

/**
 * Form field configuration.
 */
export type FormFields = (FormField | string)[];

/**
 * Form field configuration.
 */
export interface FormField {
    /** Field name */
    name: string;
    /** Type of UI component to use */
    component?: FieldComponent;
    /** Is the field a custom field that's not part of the schema? */
    custom?: boolean;
    /** GraphQL filter to use when querying for options. Only supported on comboboxes. */
    filter?: { [key: string]: RestStringFiltersInput };
    /**
     * Custom translation key for the field label (default = field name). Can also be
     * used for specifying custom placeholder text for large text fields.
     */
    translationKey?: string;

    /**
     * Formatter function to format the combobox option label. Only supported with object references.
     * @param optionData Form data for the option.
     * @returns The formatted option label.
     */
    labelFormatter?: LabelFormatter;

    /**
     * Formatter function to format displayed values. Only supported with links.
     * @param value Field value.
     * @param formData Form data.
     * @returns The formatted value.
     */
    valueFormatter?: ValueFormatter;
}

/**
 * Collection of form fields. If a field is specified as a field name, FormBuilder will
 * automatically determine the UI component to use based on the field type. Use a FormField
 * object instead if you need to override the automatic defaults.
 */
export interface FormGroup {
    /** Form group name */
    name: string;
    /** Field names or field configuration objects */
    fields: (FormField | string)[];
    /** Custom translation key for the field label (default = group name) */
    translationKey?: string;
}

/**
 * Form context for a action click handler.
 */
export interface FormContext {
    /** Form configuration */
    form: FormConfig;
    /** Form data */
    data: FormData;
    /** Function for setting left panel content */
    setLeftContent: (contentID: string, content: ReactNode) => void;
    /** Function for setting center panel content */
    setCenterContent: (contentID: string, content: ReactNode) => void;
    /** Function for setting right panel content */
    setRightContent: (contentID: string, content: ReactNode) => void;
    /** Map controller */
    mapController: MapController;
}

/**
 * Action menu item. Actions are defined as FormAction objects. '-' is used as a separator
 * and any other string is treated as a section title.
 */
export type ActionMenuItem = FormAction | string;

/**
 * String denoting action menu divider.
 */
export const DIVIDER = '-';

/**
 * Form action configuration.
 */
export interface FormAction {
    /** Action icon */
    icon?: JSX.Element;
    /** Label translation key */
    translationKey: string;
    /** Click handler */
    onClick: (context: FormContext) => void;
    /** Function for determining if the action should be enabled or disabled (default = always enabled) */
    shouldBeEnabled?: (context: FormContext) => boolean;
}

/**
 * Function to query the API for a list of objects with filters.
 */
export type FilteredQueryFunction = (
    filter: GraphQLFilter,
) => Promise<FormData[]>;

/**
 * Form configuration for the GraphQL object type.
 */
export interface FormConfig {
    /** GraphQL type name */
    gqlType: string;
    /** Model name that this form config supports. */
    model: string;
    /** Model aliases. */
    modelAliases?: string[];
    /** Object type icon. */
    icon?: JSX.Element;
    /** Formatter for object label. */
    labelFormatter?: LabelFormatter;
    /** Form groups. */
    groups: FormGroup[];
    /** Form actions. */
    actions?: ActionMenuItem[];
    /** Field names to exclude from advanced search */
    excludeFromSearch?: string[];
    /** Functions to interact with the API. */
    functions: {
        get: FilteredQueryFunction;
    };
    /** Name of the collection in the query results (usually the type name in plural form). */
    queryCollection: string;
}

export interface FormConfigs {
    [key: string]: FormConfig;
}
