import { Icons } from '@keypro/2nd-xp';
import { FormConfig } from '../types';
import { getPlans } from '@apis/keycore';
import { createComboField } from '../field-utils';

const config: FormConfig = {
    gqlType: 'Plan',
    model: 'plan',
    icon: <Icons.Area />,
    groups: [
        {
            name: 'objectData',
            fields: [
                { name: 'name', translationKey: 'identification' },
                createComboField('type', 'PLAN_TYPE'),
                createComboField('state', 'PLAN_STATE'),
                'beginDate',
                'expireDate',
                { name: 'description', component: 'textarea' },
            ],
        },
    ],
    functions: {
        get: getPlans,
    },
    queryCollection: 'plans',
};

export default config;
