import { GraphQLObjectField } from '@apis/introspection';
import { GraphQLFilter } from '@apis/utils';
import { FieldComponent, FormField, getFormConfig } from '@form-configs';

/**
 * Queries the API for the data for the given GraphQL type.
 * @param gqlType The GraphQL type to get data for.
 * @param options Options to pass to the API query.
 * @returns The data for the given GraphQL type.
 */
export const getData = (gqlType: string, filter: GraphQLFilter) => {
    const config = getFormConfig(gqlType);
    return config.functions.get(filter);
};

/**
 * Joins non-empty strings with the given separator.
 * @param items Strings to join.
 * @param separator Separator to use.
 * @returns The joined string.
 */
export const joinNonEmpty = (items: unknown[], separator: string) => {
    return items.filter(Boolean).join(separator);
};

/**
 * Convert string to camel case.
 * @param str String to convert.
 * @returns Converted string.
 */
export const toCamelCase = (str: string) => {
    return str.replace(/_./g, (match) => match[1].toUpperCase());
};

/**
 * Gets the table row for a form field based on its type and configuration.
 * @param field Field to build a form row for.
 * @param config Configuration for the field.
 */
export const getComponentType = (
    field?: GraphQLObjectField,
    config?: FormField,
): string => {
    let componentType: FieldComponent = config?.component ?? 'auto';

    const componentTypeMap: Record<string, FieldComponent> = {
        String: 'text',
        Float: 'number',
        Int: 'number',
        Number: 'number',
        Boolean: 'checkbox',
        DateTime: 'date',
        Apartment: 'address',
        TxtConstant: 'combobox',
        URL: 'link',
    };

    const name = field?.name ?? config?.name;

    if (!name) {
        throw new Error('Field name is required');
    }

    if (config?.custom && !config.component) {
        throw new Error('Custom fields must have a component type');
    }

    if (componentType === 'auto') {
        if (!field) {
            throw new Error(
                `Component type 'auto' can only be used with fields belonging to schema (field: ${name})`,
            );
        }

        if (componentTypeMap[field.type]) {
            componentType = componentTypeMap[field.type];
        } else if (!field.isScalar) {
            componentType = 'objectReference';
        }

        if (!componentType) {
            throw new Error(`Unsupported field type: ${field.type}`);
        }
    }

    if (
        config?.filter &&
        componentType !== 'combobox' &&
        componentType !== 'combobox-multi' &&
        componentType !== 'radio'
    ) {
        throw new Error(
            `Filtering can only be used with 'combobox' or 'radio' component type (field: ${name})`,
        );
    }

    return componentType;
};
