import { styled } from '@keypro/2nd-xp';
import { HTMLAttributes, useRef } from 'react';

interface SwipedToBottomListenerProps extends HTMLAttributes<HTMLDivElement> {
    // The callback function to be called when the user swipes to the bottom
    onSwipedToBottom?: () => void;
}

export const SwipedToBottomListener = ({
    children,
    onSwipedToBottom,
    ...rest
}: SwipedToBottomListenerProps) => {
    const menuContentRef = useRef<HTMLDivElement>(null);

    const handleTouchMove = () => {
        if (menuContentRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                menuContentRef.current;

            // Detect if user has reached or is very near the bottom
            if (
                scrollTop + clientHeight >= scrollHeight - 10 &&
                onSwipedToBottom
            ) {
                onSwipedToBottom();
            }
        }
    };

    return (
        <SwipeListener
            {...rest}
            ref={menuContentRef}
            onTouchMove={handleTouchMove}
        >
            {children}
        </SwipeListener>
    );
};

const SwipeListener = styled.div`
    overflow-y: auto;
    height: 100%;
`;
