import { create } from 'zustand';
import { InfoToolSelectionMode } from '@keypro/2nd-xp';
import { MenuState } from '@stores';
import { ReactNode } from 'react';

interface MobileMenuState extends MenuState {
    menuHeight: number;
    setMenuHeight: (height: number) => void;
    isDetailedToolbar: boolean;
    setIsDetailedToolbar: (isDetailedToolbar: boolean) => void;
    previousMenu: {
        menuContentId: string | null;
        menuContent: ReactNode;
        menuHeight: number;
        isDetailedToolbar: boolean;
    };
    storePreviousMenu: () => void;
    restorePreviousMenu: () => void;
    menuOverlayContent: ReactNode;
    setMenuOverlayContent: (content: ReactNode) => void;
}

const createMenuStore = () =>
    create<MobileMenuState>((set) => ({
        menuContentId: null,
        menuContent: null,
        isMenuOpen: false,
        infoToolSelectionMode: InfoToolSelectionMode.RECTANGLE,
        detailedMenu: false,
        setMenuContent: (contentID, content) =>
            set((state) => ({
                menuContentId: contentID,
                menuContent: content,
                isMenuOpen:
                    contentID === state.menuContentId
                        ? !state.isMenuOpen
                        : true,
            })),
        toggleMenu: () => set((state) => ({ isMenuOpen: !state.isMenuOpen })),
        setInfoToolSelectionMode: (mode) =>
            set({ infoToolSelectionMode: mode }),
        setDetailedMenu: (detailedMenu) => set({ detailedMenu }),

        // MobileMenuState
        menuHeight: 50,
        setMenuHeight: (height) => set({ menuHeight: height }),
        isDetailedToolbar: false,
        setIsDetailedToolbar: (isDetailedToolbar) =>
            set({ isDetailedToolbar: isDetailedToolbar }),
        previousMenu: {
            menuContentId: null,
            menuContent: null,
            menuHeight: 50,
            isDetailedToolbar: false,
        },
        storePreviousMenu: () =>
            set(
                ({
                    menuContentId,
                    menuContent,
                    menuHeight,
                    isDetailedToolbar,
                }) => ({
                    previousMenu: {
                        menuContentId,
                        menuContent,
                        menuHeight,
                        isDetailedToolbar,
                    },
                }),
            ),
        restorePreviousMenu: () =>
            set(({ previousMenu }) => {
                if (
                    previousMenu.menuContentId !== null &&
                    previousMenu.menuContent !== null
                )
                    return {
                        menuContentId: previousMenu.menuContentId,
                        menuContent: previousMenu.menuContent,
                        menuHeight: previousMenu.menuHeight,
                        isDetailedToolbar: previousMenu.isDetailedToolbar,
                        isMenuOpen: true,
                        previousMenu: {
                            menuContentId: null,
                            menuContent: null,
                            menuHeight: 50,
                            isDetailedToolbar: false,
                        },
                    };
                else return {};
            }),
        menuOverlayContent: null,
        setMenuOverlayContent: (menuOverlayContent) =>
            set({ menuOverlayContent }),
    }));

export const useMobileMenu = createMenuStore();
