query getProfileDiagram($input: ProfileDiagramArgs!) {
    getProfileDiagram(input: $input) {
        data {
            nodes {
                angle
                connEnd
                ctid
                dataType
                id
                label
                location
                ox
                oy
                radius
                scale
                shapeId
                state
                x
                y
                tech
                children {
                    angle
                    cableCount
                    ctid
                    dataType
                    ductNumber
                    ductType
                    id
                    radius
                    scale
                    shapeId
                    state
                    stateId
                    type
                    x
                    y
                }
                otherend {
                    angle
                    connEnd
                    ctid
                    dataType
                    id
                    label
                    location
                    scale
                    shapeId
                    state
                    size
                    x
                    y
                }
            }
            edges {
                connections
                ctid
                dataType
                id
                label
                location
                shapeId
                state
                tech
                x
                y
            }
            texts {
                angle
                label
                x
                y
            }
        }
    }
}
