import {
    Checkbox,
    Icons,
    MapContext,
    NumberInput,
    Select,
    Slider,
    styled,
} from '@keypro/2nd-xp';
import { PrintMenuSetting } from './PrintMenuSetting';
import React, { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import { PageInfo } from '@generated';
import { useLeftMenu } from '@stores';
import {
    dpiOptions,
    DpiValue,
    getAngleInDegrees,
    getEffectiveSize,
    convertEffectiveSizeToPoints,
    paperSizeOptions,
    PaperSizeValue,
    adjustPrintPreviewSize,
} from './utils';

interface PrintMenuSettingPartProps {
    pageInfoRef: React.MutableRefObject<PageInfo>;
}

export const PrintMenuSettingPart = ({
    pageInfoRef,
}: PrintMenuSettingPartProps) => {
    const controller = useContext(MapContext)!;
    const [rotation, setRotation] = useState(getAngleInDegrees(controller));
    const [orientation, setOrientation] = useState('landscape');
    const [paperSize, setPaperSize] = useState<PaperSizeValue>('1');
    const [dpi, setDpi] = useState<DpiValue>('1');
    const { isMenuOpen: isLeftMenuOpen } = useLeftMenu();

    // Allow slider to control map rotation
    useEffect(() => {
        const view = controller.map.getView();
        view.setRotation((rotation * Math.PI) / 180);
    }, [rotation, controller]);

    // Update slider according to compass rotation
    useEffect(() => {
        const compass = document.getElementsByClassName(
            'ol-rotate-reset',
        )[0] as HTMLElement;

        const handleMouseDown = () => {
            let hasMoved = false;

            const onMouseMove = () => {
                hasMoved = true;
            };

            const onMouseUp = () => {
                if (!hasMoved) setRotation(0);
                else {
                    const view = controller.map.getView();
                    const currentRotation = view.getRotation();
                    setRotation(Math.round((currentRotation * 180) / Math.PI));
                }

                compass.removeEventListener('mousemove', onMouseMove);
                compass.removeEventListener('mouseup', onMouseUp);
            };

            compass.addEventListener('mousemove', onMouseMove);
            compass.addEventListener('mouseup', onMouseUp);
        };

        compass.addEventListener('mousedown', handleMouseDown);

        return () => compass.removeEventListener('mousedown', handleMouseDown);
    }, [controller]);

    useEffect(() => {
        const orientationLabel = orientation === 'portrait' ? '1' : '0';
        const papersizeLabel = paperSizeOptions.find(
            (item) => item.value === paperSize,
        )!.label;
        const dpiLabel = dpiOptions.find((item) => item.value === dpi)!.label;
        const effectiveSize = getEffectiveSize(
            papersizeLabel,
            orientationLabel,
        );
        adjustPrintPreviewSize(effectiveSize);
        const { height, width } = convertEffectiveSizeToPoints(effectiveSize);

        pageInfoRef.current = {
            ...pageInfoRef.current,
            angle: -rotation, // our rotation logic is reversed compare to the old print frame
            orientation: orientationLabel,
            papersize: papersizeLabel,
            dpi: Number(dpiLabel),
            pageH: height,
            pageW: width,
        };
    }, [rotation, orientation, paperSize, dpi, isLeftMenuOpen, pageInfoRef]);

    return (
        <PrintMenuSetting
            icon={<Icons.PrintSettings />}
            title={t('settings')}
            isOn={false}
        >
            <StyledPrintMenuSettingTextField data-testid="printController-papersize">
                <StyledPrintMenuSettingTextFieldLabel>
                    {t('paperSize')}
                </StyledPrintMenuSettingTextFieldLabel>
                <StyledSelect
                    isFilterable
                    options={paperSizeOptions}
                    defaultValue="1"
                    onChangeValue={(value) => {
                        if (typeof value === 'string') {
                            setPaperSize(value);
                        }
                    }}
                />
            </StyledPrintMenuSettingTextField>
            <StyledPrintMenuSettingTextField>
                <StyledPrintMenuSettingTextFieldLabel>
                    DPI
                </StyledPrintMenuSettingTextFieldLabel>
                <StyledSelect
                    isFilterable
                    options={dpiOptions}
                    defaultValue="2"
                    onChangeValue={(value) => {
                        if (typeof value === 'string') {
                            setDpi(value);
                        }
                    }}
                />
            </StyledPrintMenuSettingTextField>
            <StyledPrintMenuSettingTextField>
                <StyledPrintMenuSettingTextFieldLabel>
                    {t('rotation')}
                </StyledPrintMenuSettingTextFieldLabel>
                <StyledPrintMenuSettingTextFieldNumberInput data-testid="printController-rotation">
                    <NumberInput
                        value={rotation}
                        min={-360}
                        max={360}
                        symbol="º"
                        onChange={(value) => {
                            setRotation(value ?? 0);
                        }}
                        style={{ width: '52px' }}
                    />
                    <Slider
                        min="-360"
                        max="360"
                        value={rotation.toString()}
                        onChange={(value) => {
                            setRotation(Number(value));
                        }}
                        style={{ width: '113px' }}
                    />
                </StyledPrintMenuSettingTextFieldNumberInput>
            </StyledPrintMenuSettingTextField>
            <StyledPrintMenuSettingRadio>
                <StyledPrintMenuSettingRadioTitle>
                    {t('orientation')}
                </StyledPrintMenuSettingRadioTitle>
                <StyledPrintMenuSettingRadioGroup data-testid="printController-orientation">
                    <StyledPrintMenuSettingRadioItem>
                        <StyledCheckbox
                            type="radio"
                            id="landscape"
                            name="orientation"
                            onChange={() => {
                                setOrientation('landscape');
                            }}
                            checked={orientation === 'landscape'}
                        />
                        <StyledPrintMenuSettingRadioItemLabel>
                            <Icons.Landscape />
                            {t('landscape')}
                        </StyledPrintMenuSettingRadioItemLabel>
                    </StyledPrintMenuSettingRadioItem>
                    <StyledPrintMenuSettingRadioItem>
                        <StyledCheckbox
                            type="radio"
                            id="portrait"
                            name="orientation"
                            onChange={() => {
                                setOrientation('portrait');
                            }}
                            checked={orientation === 'portrait'}
                        />
                        <StyledPrintMenuSettingRadioItemLabel>
                            <Icons.Portrait />
                            {t('portrait')}
                        </StyledPrintMenuSettingRadioItemLabel>
                    </StyledPrintMenuSettingRadioItem>
                </StyledPrintMenuSettingRadioGroup>
            </StyledPrintMenuSettingRadio>
        </PrintMenuSetting>
    );
};

const StyledPrintMenuSettingTextField = styled.div`
    display: flex;
    align-items: center;
`;

const StyledSelect = styled(Select)`
    width: 185px;
    height: 32px;
    margin-left: auto;
`;

const StyledPrintMenuSettingTextFieldLabel = styled.div`
    ${(props) => props.theme.fonts['14px Regular']};
    color: ${(props) => props.theme.colors.neutral['90']};
`;

const StyledPrintMenuSettingTextFieldNumberInput = styled.div`
    display: flex;
    gap: 8px;
    width: 185px;
    height: 32px;
    margin-left: auto;
`;

const StyledPrintMenuSettingRadio = styled.div`
    padding-top: 8px;
`;

const StyledPrintMenuSettingRadioTitle = styled.div`
    ${(props) => props.theme.fonts['14px Regular']};
    color: ${(props) => props.theme.colors.neutral['90']};
`;

const StyledPrintMenuSettingRadioGroup = styled.div`
    display: flex;
    gap: 16px;
`;

const StyledPrintMenuSettingRadioItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const StyledCheckbox = styled(Checkbox)`
    margin-left: 8px;
    & > svg {
        width: 18px;
        height: 18px;
    }
`;

const StyledPrintMenuSettingRadioItemLabel = styled.label`
    display: flex;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
    gap: 4px;
    & > svg {
        width: 20px;
        height: 20px;
    }
`;
