import { Icons } from '@keypro/2nd-xp';
import { FormConfig } from '../types';
import { getApartments } from '@apis/keycore';
import { createComboField } from '../field-utils';

const config: FormConfig = {
    gqlType: 'Apartment',
    model: 'apartment',
    labelFormatter: (apartment) => {
        return `${apartment?.apartmentLetter} ${apartment?.apartmentNumber}`;
    },
    icon: <Icons.AddressPoint />,
    groups: [
        {
            name: 'objectData',
            fields: [
                { name: 'apartmentLetter', translationKey: 'letter' },
                { name: 'apartmentNumber', translationKey: 'number' },
                { name: 'apartmentDivision', translationKey: 'division' },
                createComboField('usagePurpose', 'APARTMENT_USAGE'),
                createComboField('usagePurposeAlt', 'KN_ADDR_SPEC'),
                createComboField('usageState', 'APARTMENT_STATUS'),
            ],
        },
        {
            name: 'dataSource',
            fields: [
                'sourceId',
                createComboField('sourceSystem', 'SOURCE_SYSTEM'),
                'sourceSystemKey',
            ],
        },
    ],
    functions: {
        get: getApartments,
    },
    queryCollection: 'apartments',
};

export default config;
