query GetMapConfig {
    getMapConfig(name: "NEW_UI") {
        mapConfig {
            optionsV2
            options
            layerConfigs {
                pk
                type
                name
                label
                url
                params
                options
                paramsV2
                optionsV2
                order
                system
                printUrl
                model {
                    id
                }
            }
            layerSwitcherConfigs
        }
    }
}