import { MapConfig, MapController, SelectOptionItem } from '@keypro/2nd-xp';
import { Map } from 'ol';

/**
 * Returns the current rotation angle of the map view in degrees.
 *
 * @param {MapController} controller - The MapController instance.
 * @returns {number} The current rotation angle of the map in degrees.
 */
export const getAngleInDegrees = (controller: MapController) =>
    Math.round((controller.map.getView().getRotation() * 180) / Math.PI);

const baseA4 = { width: 210, height: 297 };

export const paperSizeOptions: SelectOptionItem[] = [
    { label: 'A4', value: '1' },
    { label: '3xA4', value: '2' },
    { label: '4xA4', value: '3' },
    { label: '5xA4', value: '4' },
    { label: 'A3', value: '5' },
    { label: 'A2', value: '6' },
    { label: 'A1', value: '7' },
    { label: 'A0', value: '8' },
];

export type PaperSizeValue = (typeof paperSizeOptions)[number]['value'];

export const dpiOptions: SelectOptionItem[] = [
    { label: '150', value: '1' },
    { label: '300', value: '2' },
    { label: '450', value: '3' },
    { label: '600', value: '4' },
];

export type DpiValue = (typeof paperSizeOptions)[number]['value'];

export interface EffectiveSize {
    effectiveWidth: number;
    effectiveHeight: number;
}

/**
 * Calculates the effective width and height based on the given paper size and orientation.
 * In landscape orientation, width is largerSide; in portrait orientation, width is smallerSide.
 *
 * @param {PaperSizeValue} paperLabel - Label of the paper size, like 'A4', '3xA4', etc.
 * @param {string} orientationLabel - Orientation of the paper; '0' for landscape, otherwise portrait.
 * @returns - An object containing the effective width and height.
 */
export const getEffectiveSize = (
    paperLabel: PaperSizeValue,
    orientationLabel: string,
): EffectiveSize => {
    const seriesMultipliers: Record<'A4' | 'A3' | 'A2' | 'A1' | 'A0', number> =
        {
            A4: 1,
            A3: Math.sqrt(2),
            A2: 2,
            A1: 2 * Math.sqrt(2),
            A0: 4,
        };

    const seriesMultiplier =
        seriesMultipliers[paperLabel as keyof typeof seriesMultipliers] || 1;
    const regex = /^(\d+)xA4$/;
    const customMultiplierMatch = regex.exec(paperLabel);

    const customMultiplier = customMultiplierMatch
        ? parseInt(customMultiplierMatch[1], 10)
        : 1;

    const [largerSide, smallerSide] = [
        baseA4.width * seriesMultiplier * customMultiplier,
        baseA4.height * seriesMultiplier,
    ].sort((a, b) => b - a);

    return {
        effectiveWidth: orientationLabel === '0' ? largerSide : smallerSide,
        effectiveHeight: orientationLabel === '0' ? smallerSide : largerSide,
    };
};

/**
 * Converts the effective page dimensions from millimeters to points.
 *
 * @param {EffectiveSize} effectiveSize - The size of the page in millimeters, containing the effective width and height.
 * @returns {Object} - An object with the width and height converted to points.
 */
export const convertEffectiveSizeToPoints = (effectiveSize: EffectiveSize) => {
    const { effectiveWidth, effectiveHeight } = effectiveSize;
    const width = (effectiveWidth / 25.4) * 72;
    const height = (effectiveHeight / 25.4) * 72;
    return { width, height };
};

/**
 * Adjusts  the print preview size based on the available space in the center menu.
 * The print preview dimensions are scaled to fit 80% of the available width and height.
 *
 * @param {EffectiveSize} effectiveSize - The effective size of the print preview in millimeters.
 */
export const adjustPrintPreviewSize = (effectiveSize: EffectiveSize) => {
    const { effectiveWidth, effectiveHeight } = effectiveSize;
    const centerMenu = document.getElementById('center-menu')!;
    const maxWidth = centerMenu.offsetWidth;
    const maxHeight = centerMenu.offsetHeight;

    const scale =
        Math.min(maxWidth / effectiveWidth, maxHeight / effectiveHeight) * 0.8; // cap at 80% of center menu screen

    const printPaperSize = document.getElementById(
        'printPreview-printPaperSize',
    );
    if (printPaperSize) {
        printPaperSize.style.transition = 'width 0.3s ease, height 0.3s ease';
        printPaperSize.style.width = `${effectiveWidth * scale}px`;
        printPaperSize.style.height = `${effectiveHeight * scale}px`;
    }
};

type LayerConfig = MapConfig['layerConfigs'][number] & { printUrl?: string };

/**
 * Generates a URL based on the specified layer configuration.
 * - If a `printUrl` is provided, appends the SRS parameter `EPSG:3067` to it.
 * - Otherwise, construct the suitable url.
 *
 * Note: The placeholder `tenant-backend-url` will be replaced by the gateway with the correct backend resource, if required.
 *
 * @param {LayerConfig} layer_config - The configuration for the map layer, which may contain a print URL and layer parameters.
 * @returns {string} The generated URL based on the provided layer configuration.
 */
export const generateLayerUrl = (layer_config: LayerConfig) => {
    if (layer_config.printUrl) {
        return layer_config.printUrl + '&SRS=EPSG:3067&';
    }

    const baseUrl = layer_config.url.includes('maps/layers')
        ? 'tenant-backend-url/maps/layers/'
        : layer_config.url;

    return `${baseUrl}?mode=map&layers=${layer_config.paramsV2.layers}&`;
};

/**
 * Calculates the extent of the print preview area.
 * This function requires `printPreview-printPaperSize`.
 * Return the calculated minimum and maximum x/y coordinates.
 *
 * @param {Map} map - The map object.
 * @returns {Object} An object with `minX`, `minY`, `maxX`, and `maxY` defining the print area extent.
 */
const calculatePrintAreaExtent = (map: Map) => {
    const printPaperSize = document.getElementById(
        'printPreview-printPaperSize',
    )!;
    const rect = printPaperSize.getBoundingClientRect();
    const mapViewport = map.getViewport();
    const mapRect = mapViewport.getBoundingClientRect();

    const minX = rect.left - mapRect.left;
    const minY = rect.top - mapRect.top;
    const maxX = minX + rect.width;
    const maxY = minY + rect.height;

    return { minX, minY, maxX, maxY };
};

/**
 * Generates a GeoJSON polygon string representing the print preview area on the map.
 *
 * This function calculates the coordinates of all four corners of the print area
 * (top-left, top-right, bottom-right, bottom-left) to ensure accuracy, especially if the
 * map has been rotated. It returns a GeoJSON polygon string with these coordinates to define
 * the print area.
 *
 * @param {Map} map - The map object.
 * @returns {string | null} A GeoJSON string defining the print area's polygon, or `null` if not applicable.
 */
export const getPrintAreaGeojson = (map: Map): string | null => {
    const { minX, minY, maxX, maxY } = calculatePrintAreaExtent(map);

    // Must use all 4 points instead of only 2 diagonal points to ensure correct print extent in case of rotation
    const topLeft = map.getCoordinateFromPixel([minX, minY]);
    const topRight = map.getCoordinateFromPixel([maxX, minY]);
    const bottomRight = map.getCoordinateFromPixel([maxX, maxY]);
    const bottomLeft = map.getCoordinateFromPixel([minX, maxY]);

    const coordinates = `[[[${topLeft[0]},${topLeft[1]},0],[${topRight[0]},${topRight[1]},0],[${bottomRight[0]},${bottomRight[1]},0],[${bottomLeft[0]},${bottomLeft[1]},0],[${topLeft[0]},${topLeft[1]},0]]]`;
    return `{"type":"Feature","properties":{"_meta":{"active_z_lock":true,"active_z":"0"}},"geometry":{"type":"Polygon","coordinates":${coordinates}}}`;
};
