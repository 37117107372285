import {
    Toolbar,
    ToolbarGroup,
    Button,
    Icons,
    PreviousMapHistoryButton,
    NextMapHistoryButton,
    PlacementScaleMenu,
    ZoomSlider,
    DisplayScaleMenu,
    InfoToolMenu,
    PanButton,
    MoreMenu,
    HelpButton,
    useIsMobile,
    MenuDivider,
    MenuItem,
    MeasureToolMenu,
    LocateMeButton,
    ZoomToAreaButton,
    MapContext,
} from '@keypro/2nd-xp';
import { UserWidget } from './UserWidget/UserWidget';
import Bell from '@assets/mockups/Bell.svg';
import { t } from 'i18next';
import { PrintMenu, PrintPreview } from '@components/PrintMapOverlay';
import { useContext, useEffect, useState } from 'react';
import {
    useLeftMenu,
    useRightMenu,
    useCenterMenu,
    useMobileMenu,
} from '@stores';
import SearchField from '@components/Search/SearchField';
import {
    helpUrl,
    getMeasureToolMenuProps,
    getMoreMenuProps,
    getInfoToolMenuProps,
    getLocateMeButtonProps,
    getZoomToAreaButtonProps,
} from './Common';
import MobileDetailedToolbar from './MobileDetailedToolbar';
import { QuickSearchResults } from '@components/Search/search-components/QuickSearchResult';

/**
 * The topmost horizontal menu that contains most of the app's tools.
 * It consists of three tool groups, with content varying based on Desktop, Tablet, or Mobile mode.
 */
const AppToolbar = (): JSX.Element => {
    const { setMenuContent: setRightMenuContent } = useRightMenu();
    const { setMenuContent: setCenterMenuContent, setInfoToolSelectionMode } =
        useCenterMenu();
    const { detailedMenu, setDetailedMenu } = useLeftMenu();
    const {
        setMenuHeight,
        setMenuContent: setMobileMenuContent,
        detailedMenu: detailedMobileMenu,
        setDetailedMenu: setDetailedMobileMenu,
        isDetailedToolbar,
        setIsDetailedToolbar,
    } = useMobileMenu();
    const controller = useContext(MapContext)!;
    const [isMapInitialized, setIsMapInitialized] = useState(
        controller.initialized,
    );

    useEffect(() => {
        const handleInitialize = () => {
            setIsMapInitialized(true);
        };

        controller.on('initialize', handleInitialize);

        return () => {
            controller.off('initialize', handleInitialize);
        };
    }, [controller]);

    const isMobile = useIsMobile();
    const isTablet = useIsMobile({ isTablet: true, tablet: 1695 });
    const isDesktop = !isMobile && !isTablet;

    const toggleDetailedNavbar = () =>
        isMobile
            ? setDetailedMobileMenu(!detailedMobileMenu)
            : setDetailedMenu(!detailedMenu);

    const measureToolMenuProps = getMeasureToolMenuProps();
    const moreMenuProps = getMoreMenuProps();
    const infoToolMenuProps = getInfoToolMenuProps();
    const locateMeButtonProps = getLocateMeButtonProps();
    const zoomToAreaButtonProps = getZoomToAreaButtonProps();

    const openPrintMenus = () => {
        setRightMenuContent(t('print'), <PrintMenu />);
        setCenterMenuContent(t('print'), <PrintPreview />);
    };

    const openMobileSearch = () => {
        setIsDetailedToolbar(true);
        setMenuHeight(100);
        setMobileMenuContent('QuickSearchResult', <QuickSearchResults />);
    };

    const leftToolGroup = (): JSX.Element[] => {
        const toolGroup = [
            <ToolbarGroup key={'toolbar-detailedNavbar'}>
                <Button
                    kind="ghost"
                    data-tooltip={t('menu')}
                    data-testid="toolbar-detailedNavbar"
                    onClick={toggleDetailedNavbar}
                >
                    <Icons.Burger />
                </Button>
            </ToolbarGroup>,
            !isMobile && (
                <ToolbarGroup key={'toolbar-searchField'}>
                    <SearchField data-testid="toolbar-searchField" />
                </ToolbarGroup>
            ),
            !isMobile && (
                <ToolbarGroup style={{ gap: 0 }} key={'toolbar-history'}>
                    <PreviousMapHistoryButton
                        data-tooltip={t('prevMapHistoryTooltip')}
                    />
                    <NextMapHistoryButton
                        data-tooltip={t('nextMapHistoryTooltip')}
                    />
                </ToolbarGroup>
            ),
        ];

        return toolGroup as JSX.Element[];
    };

    const middleToolGroup = (): JSX.Element[] => {
        const toolGroup = [
            isDesktop && (
                <LocateMeButton
                    {...locateMeButtonProps}
                    data-testid="toolbar-locateMe"
                    key={'toolbar-locateMe'}
                    disabled={!isMapInitialized}
                />
            ),
            <PlacementScaleMenu
                data-tooltip={t('placementScaleTooltip')}
                title={t('placementScale')}
                key={'toolbar-placementScale'}
            />,
            <ZoomSlider
                data-tooltip={t('zoomSliderTooltip')}
                key={'toolbar-zoomSlider'}
            />,
            <DisplayScaleMenu
                data-testid="toolbar-displayScale"
                data-tooltip={t('displayScaleTooltip')}
                title={t('displayScale')}
                key={'toolbar-displayScale'}
            />,
            isDesktop && (
                <ZoomToAreaButton
                    {...zoomToAreaButtonProps}
                    key={'toolbar-zoomToAreaButton'}
                    disabled={!isMapInitialized}
                />
            ),
        ];
        if (isMobile) toolGroup.length = 0;

        return toolGroup as JSX.Element[];
    };

    const rightToolGroup = (): JSX.Element[] => {
        const toolGroup = [
            isTablet && (
                <LocateMeButton
                    {...locateMeButtonProps}
                    data-testid="toolbar-locateMe"
                    key={'toolbar-locateMe'}
                    disabled={!isMapInitialized}
                />
            ),
            isTablet && (
                <ZoomToAreaButton
                    {...zoomToAreaButtonProps}
                    key={'toolbar-zoomToAreaButton'}
                    disabled={!isMapInitialized}
                />
            ),
            <PanButton
                data-tooltip={t('panTooltip')}
                key={'toolbar-panButton'}
            />,
            <InfoToolMenu
                {...infoToolMenuProps}
                data-testid="toolbar-infoToolMenu"
                onChangeMode={(mode) => {
                    setInfoToolSelectionMode(mode);
                }}
                key={'toolbar-infoToolMenu'}
            />,
            isDesktop && (
                <MeasureToolMenu
                    {...measureToolMenuProps}
                    key={'toolbar-measureToolMenu'}
                />
            ),
            isDesktop && (
                <Button
                    data-testid="toolbar-print"
                    kind="ghost"
                    label={t('print')}
                    data-tooltip={t('printTooltip')}
                    iconPosition="left"
                    onClick={openPrintMenus}
                    responsiveLabel
                    key={'toolbar-print'}
                    disabled={!isMapInitialized}
                >
                    <Icons.Print />
                </Button>
            ),
            isDesktop && (
                <MoreMenu {...moreMenuProps} key={'toolbar-moreMenu'} />
            ),
            isTablet && (
                <MoreMenu {...moreMenuProps} key={'toolbar-moreMenu'}>
                    <MeasureToolMenu
                        {...measureToolMenuProps}
                        asMenuItems
                        menuItemsGroupTitle="Measure"
                    />
                    <MenuDivider />
                    <MenuItem
                        iconLeft={<Icons.Print />}
                        leftContent={t('print')}
                        onClick={openPrintMenus}
                        disabled={!isMapInitialized}
                    />
                    <MenuDivider />
                </MoreMenu>
            ),

            <Icons.Divider
                style={{ height: '32px', width: '2px' }}
                key={'toolbar-divider'}
            />,
            <Button
                kind="ghost"
                data-tooltip={t('notificationsTooltip')}
                key={'toolbar-notification'}
            >
                <img alt="Notifications" src={Bell} />
            </Button>,
            <HelpButton
                url={helpUrl}
                data-tooltip={t('helpTooltip')}
                key={'toolbar-helpButton'}
            />,
        ];
        if (isMobile) toolGroup.length = 0;
        isMobile &&
            toolGroup.push(
                <Button
                    kind="ghost"
                    data-tooltip={t('openFullSearch')}
                    data-testid="toolbar-searchBtn"
                    key={'toolbar-mobileSearchBtn'}
                    onClick={openMobileSearch}
                >
                    <Icons.Search />
                </Button>,
            );
        toolGroup.push(<UserWidget key={'toolbar-userWidget'} />);

        return toolGroup as JSX.Element[];
    };

    const renderToolGroup = (toolGroup: JSX.Element[], gap?: number) =>
        toolGroup.length ? (
            <ToolbarGroup style={{ gap: gap ?? undefined }}>
                {toolGroup}
            </ToolbarGroup>
        ) : null;

    if (isMobile && isDetailedToolbar) return <MobileDetailedToolbar />;

    return (
        <Toolbar data-testid="app-toolbar" id="app-toolbar">
            {renderToolGroup(leftToolGroup(), 20)}
            {renderToolGroup(middleToolGroup())}
            {renderToolGroup(rightToolGroup())}
        </Toolbar>
    );
};

export default AppToolbar;
