import { Icons } from '@keypro/2nd-xp';
import { ActionMenuItem, DIVIDER, FormAction } from './types';
import { getWKT } from './utils';

/**
 * Zoom to the object location.
 */
export const zoomAction: FormAction = {
    icon: <Icons.ZoomIn />,
    translationKey: 'zoomTo',
    onClick: (context) => {
        const wkt = getWKT(context.data);

        if (wkt) {
            const geometry = context.mapController.wktToGeometry(wkt);
            const extent = geometry.getExtent();
            context.mapController.zoomToExtent(extent);
        }
    },
    shouldBeEnabled: (context) => !!getWKT(context.data),
};

/**
 * Show attribute table for the object.
 */
export const attributeTableAction: FormAction = {
    icon: <Icons.Table />,
    translationKey: 'attributeTable',
    onClick: () => console.log('Not implemented'),
    shouldBeEnabled: () => false,
};

/**
 * Show version history for the object.
 */
export const versionHistoryAction: FormAction = {
    icon: <Icons.Version />,
    translationKey: 'showVersionHistory',
    onClick: () => console.log('Not implemented'),
    shouldBeEnabled: () => false,
};

/**
 * Modify the object.
 */
export const modifyAction: FormAction = {
    icon: <Icons.Modify2 />,
    translationKey: 'modify',
    onClick: () => console.log('Not implemented'),
    shouldBeEnabled: () => false,
};

/**
 * Duplicate the object.
 */
export const duplicateAction: FormAction = {
    icon: <Icons.Duplicate />,
    translationKey: 'duplicate',
    onClick: () => console.log('Not implemented'),
    shouldBeEnabled: () => false,
};

/**
 * Save the object to a list.
 */
export const bookmarkAction: FormAction = {
    icon: <Icons.BookmarkSave />,
    translationKey: 'saveToList',
    onClick: () => console.log('Not implemented'),
    shouldBeEnabled: () => false,
};

/**
 * Pin the object.
 */
export const pinAction: FormAction = {
    icon: <Icons.Pin />,
    translationKey: 'pin',
    onClick: () => console.log('Not implemented'),
    shouldBeEnabled: () => false,
};

/**
 * Annotate the object.
 */
export const annotateAction: FormAction = {
    icon: <Icons.Annotation />,
    translationKey: 'annotateObject',
    onClick: () => console.log('Not implemented'),
    shouldBeEnabled: () => false,
};

/**
 * Delete the object.
 */
export const deleteAction: FormAction = {
    icon: <Icons.Trash />,
    translationKey: 'deleteObject',
    onClick: () => console.log('Not implemented'),
    shouldBeEnabled: () => false,
};

/**
 * Standard actions on the top of the menu.
 */
export const defaultTopActions: ActionMenuItem[] = [
    zoomAction,
    DIVIDER,
    attributeTableAction,
];

/**
 * Standard actions on the middle of the menu.
 */
export const defaultMiddleActions: ActionMenuItem[] = [
    versionHistoryAction,
    DIVIDER,
    modifyAction,
    duplicateAction,
    DIVIDER,
    bookmarkAction,
    pinAction,
    annotateAction,
];

/**
 * Standard actions on the bottom of the menu.
 */
export const defaultBottomActions: ActionMenuItem[] = [DIVIDER, deleteAction];
