import { InfoObject } from '@components';
import {
    getLabel,
    getModelIcon,
    getTranslatedTitle,
    getTypeByModel,
} from '@form-configs';
import { Maybe, SearchResult } from '@generated';
import { useResponsivePan } from '@hooks/map';
import {
    Icons,
    MapContext,
    QuickSearchResultCard,
    useIsMobile,
} from '@keypro/2nd-xp';
import { useMobileMenu, useRightMenu, useSearchStore } from '@stores';
import { t } from 'i18next';
import { useContext } from 'react';

interface SearchResultCardProps {
    result: SearchResult;
    index: number;
}

/**
 * Creates a search result card for the given search result.
 * @param result Search result.
 * @param index Index of the search result.
 * @returns Search result card.
 */
export const SearchResultCard = ({ result, index }: SearchResultCardProps) => {
    const {
        searchTerm: searchValue,
        setIsHighlighted,
        setHighlightPosition,
    } = useSearchStore();
    const mapController = useContext(MapContext)!;
    const { setMenuContent: setRightMenuContent } = useRightMenu();
    const {
        setMenuContent: setMobileMenuContent,
        menuHeight,
        setMenuHeight,
        storePreviousMenu,
        setIsDetailedToolbar,
    } = useMobileMenu();
    const isMobile = useIsMobile();
    const responsivePan = useResponsivePan(mapController);

    const setMenuContent = isMobile
        ? setMobileMenuContent
        : setRightMenuContent;

    let name = result.identification ?? '';
    let info = t('unknownObjectType');

    if (result.modelName) {
        const gqlType = getTypeByModel(result.modelName);
        name = getLabel(gqlType, result);
        info = getTranslatedTitle(gqlType);
    }

    /**
     * Locates and highlights the object on the map.
     * @param location Location of the object.
     */
    const locate = (location: Maybe<string> | undefined) => {
        if (!location) return;

        const geom = mapController.wktToGeometry(location);

        if (geom) {
            const extent = geom.getExtent();
            const center = [
                (extent[0] + extent[2]) / 2,
                (extent[1] + extent[3]) / 2,
            ];

            setIsHighlighted(true);
            setHighlightPosition(center);
            if (menuHeight === 100) setMenuHeight(50);
            responsivePan(center, 50);
        }
    };

    /**
     * Shows the object in the right menu.
     * @param model Model of the object.
     * @param id ID of the object.
     */
    const showObject = (model: string, id: number) => {
        if (isMobile) {
            storePreviousMenu();
            setIsDetailedToolbar(false);
            setMenuHeight(50);
        }
        setMenuContent(
            `QuickSearchObject-${model}-${id}`,
            <InfoObject model={model} id={id.toString()} />,
        );
    };

    return (
        <QuickSearchResultCard
            key={result.id}
            name={name}
            info={info}
            objectIcon={getModelIcon(result.modelName)}
            openObjectIcon={<Icons.Open data-tooltip={t('openForm')} />}
            locateIcon={
                <Icons.Locate2 data-tooltip={t('locateAndHighlight')} />
            }
            searchTerm={searchValue}
            onLocateAction={() => locate(result.location)}
            onOpenObjectAction={() => showObject(result.modelName!, result.id!)}
            data-testid={`search-result-${index}`}
            onClickAction={() => showObject(result.modelName!, result.id!)}
            alwaysShowButtons={!!result.location && isMobile}
        />
    );
};
