query getTxtConstants($filter: TxtConstantsFilter $limit: Int) {
	getTxtConstants(filter: $filter limit: $limit) {
        txtConstants {
    	    id
            txt
            groupname
            orderno
            txtValue
            numValue
            numValue2
            numValue3
            numValue4
            value
            value2
            value3
            value4
            additionalData
        }
    }
}
