import { MapController, useIsMobile } from '@keypro/2nd-xp';
import { useMobileMenu } from '@stores';

/**
 * Custom hook to animate map panning with optional mobile-specific adjustments.
 * @param mapController The map controller managing the map view and animations.
 * @returns A function to trigger responsive pan animation.
 */
export const useResponsivePan = (mapController: MapController) => {
    const { menuHeight } = useMobileMenu();
    const isMobile = useIsMobile();

    /**
     * Animates the map to pan to a specified center. For mobile devices, adjusts the pan
     * to account for the height of the mobile menu to ensure the map is correctly centered.
     *
     * @param center The target center coordinates to pan to on the map.
     * @param mobileMenuHeight (Optional) The height of the mobile menu in percentage (0-100).
     *                         If not provided, the hook will use the `menuHeight` from the state.
     */
    const responsivePan = (center: number[], mobileMenuHeight?: number) => {
        mapController.map.getView().animate({ center, duration: 500 }, () => {
            if (isMobile) {
                const verticalOffsetFactor =
                    1.9 - (mobileMenuHeight ?? menuHeight) / 100;
                const centerInPixel =
                    mapController.map.getPixelFromCoordinate(center);
                const mobileCenter = mapController.map.getCoordinateFromPixel([
                    centerInPixel[0],
                    centerInPixel[1] * verticalOffsetFactor,
                ]);

                mapController.pan(mobileCenter);
            }
        });
    };

    return responsivePan;
};
