import { getManholes, getProfileDiagram } from '@apis/keycom';
import { DIVIDER, FormConfig, FormContext } from '../types';
import {
    addressGroup,
    commonGroup,
    planGroup,
    telecomAreaGroup,
} from '../groups';
import { Icons } from '@keypro/2nd-xp';
import {
    defaultTopActions,
    defaultMiddleActions,
    defaultBottomActions,
} from '../actions';
import { createComboField } from '../field-utils';

/**
 * Temporary placeholder for diagram opening function. Only logs the response.
 * @param context The form context.
 */
const openDiagram = (context: FormContext) => {
    getProfileDiagram({ id: context.data.id, type: context.form.model })
        .then((response) => {
            console.log('Diagram response: ', response);
        })
        .catch((error) => {
            console.error('Diagram error: ', error);
        });
};

const config: FormConfig = {
    gqlType: 'Manhole',
    model: 'manhole',
    icon: <Icons.Manhole />,
    groups: [
        {
            name: 'objectData',
            fields: [
                createComboField('owner', 'MANHOLE_OWNER'),
                createComboField('renter', 'MANHOLE_OWNER'),
                createComboField('type', 'MANHOLE_TYPE'),
                'identification',
                'installYear',
                createComboField('usageState', 'MANHOLE_STATE'),
                {
                    name: 'diagramConfirm',
                    component: 'radio',
                    filter: {
                        groupname: { eq: 'MANHDIAG_CONFIRMED' },
                    },
                },
                {
                    name: 'remarks',
                    component: 'textarea',
                },
            ],
        },
        addressGroup,
        telecomAreaGroup,
        planGroup,
        {
            name: 'common',
            fields: [
                ...commonGroup.fields,
                createComboField('safetyDistance', 'SAFETY_DISTANCE'),
            ],
        },
    ],
    actions: [
        ...defaultTopActions,
        {
            icon: <Icons.Diagram />,
            translationKey: 'manholeDiagram',
            onClick: openDiagram,
        },
        ...defaultMiddleActions,
        DIVIDER,
        'connectionsManagement',
        {
            translationKey: 'physical',
            onClick: () => console.log('Not implemented'),
            shouldBeEnabled: () => false,
        },
        {
            translationKey: 'logical',
            onClick: () => console.log('Not implemented'),
            shouldBeEnabled: () => false,
        },
        {
            translationKey: 'ductConnections',
            onClick: () => console.log('Not implemented'),
            shouldBeEnabled: () => false,
        },
        DIVIDER,
        'export',
        {
            icon: <Icons.ExcelFile />,
            translationKey: 'billOfMaterials',
            onClick: () => console.log('Not implemented'),
            shouldBeEnabled: () => false,
        },
        {
            icon: <Icons.ExcelFile />,
            translationKey: 'ductConnectionReport',
            onClick: () => console.log('Not implemented'),
            shouldBeEnabled: () => false,
        },
        {
            icon: <Icons.AdvancedFile />,
            translationKey: 'advancedExport',
            onClick: () => console.log('Not implemented'),
            shouldBeEnabled: () => false,
        },
        ...defaultBottomActions,
    ],
    excludeFromSearch: [
        'address',
        'addressBind',
        'telecomArea',
        'telecomAreaBind',
        'plan',
        'planIncludeCompleted',
        'historyDate',
    ],
    functions: {
        get: getManholes,
    },
    queryCollection: 'manholes',
};

export default config;
