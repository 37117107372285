import {
    GetUserQuery,
    MutationLoginArgs,
    Mutation,
    CheckAuthQuery,
    GenericAuthResponse,
    GetAuth0ConfigQuery,
    GetProductQuery,
} from '@generated';
import { sendGraphQLRequest } from '@utils';
import GetUser from './queries/GetUser.graphql';
import LoginMutation from './queries/LoginMutation.graphql';
import CheckAuth from './queries/CheckAuth.graphql';
import Logout from './queries/Logout.graphql';
import GetAuth0 from './queries/GetAuth0.graphql';
import GetProduct from './queries/GetProduct.graphql';
import { User } from '@models';

export const getUser = async (): Promise<User | undefined> => {
    const response = await sendGraphQLRequest<GetUserQuery>(GetUser);
    const data = response.data.getUser;
    if (data) {
        const user = data as User;
        if (data.firstName && data.lastName) {
            user.initials = data.firstName.charAt(0) + data.lastName.charAt(0);
        } else {
            user.initials =
                data.username?.slice(0, 2) ?? data.email?.slice(0, 2);
        }
        return user;
    }
    return undefined;
};

export async function login(data: { username: string; password: string }) {
    const { username, password } = data;

    const variables: MutationLoginArgs = {
        username,
        password,
    };
    const res = await sendGraphQLRequest<Mutation>(LoginMutation, variables);

    return res.data.login?.success === true;
}

export async function checkAuth() {
    const res = await sendGraphQLRequest<CheckAuthQuery>(CheckAuth);
    const success = res.data.checkAuth?.success;

    return success === true;
}

export async function logout() {
    const res = await sendGraphQLRequest<GenericAuthResponse>(Logout);
    const success = res.data.success;
    return success;
}

export async function getAuth0Config() {
    const res = await sendGraphQLRequest<GetAuth0ConfigQuery>(GetAuth0);
    return res.data.getAuth0Config?.auth0Config;
}

export async function getProduct() {
    const res = await sendGraphQLRequest<GetProductQuery>(GetProduct);
    return res.data.getProduct!.product!;
}
