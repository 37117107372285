query getPoles($filter: PolesFilter) {
  getPoles(filter: $filter) {
     poles{
      id
      poleNumber
      linePart
      checkoutDate
      installYear
      length
      remarks
      surveyAmount
      mapper
      historyDate
      location
      angle
      scale
      createdBy
      createdTs
      updatedBy
      updatedTs
      owner {id}
      renter {id}
      poleType {id}
      usageState {id}
      material {id}
      impregnant {id}
      stanchion {id}
      decayClass {id}
      stationDecayClass {id}
      plan {id, name}
      telecomArea {id, areaSize, perimeter}
      locationAccuracy {id}
      heightAccuracy {id}
      mappingMethod {id}
    }
  }
}
