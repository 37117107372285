import {
    ProfileDiagramResponse,
    ProfileDiagramArgs,
    GetProfileDiagramQuery,
} from '@generated';
import { sendGraphQLRequest } from '@utils';
import GetProfileDiagram from './queries/GetProfileDiagram.graphql';

/**
 * Get profile diagram.
 * @returns {Promise<ProfileDiagramResponse>} The profile diagram response.
 * @throws {Error} If the request fails.
 */
export const getProfileDiagram = async (
    args: ProfileDiagramArgs,
): Promise<ProfileDiagramResponse> => {
    try {
        const response = await sendGraphQLRequest<GetProfileDiagramQuery>(
            GetProfileDiagram,
            {
                input: args,
            },
        );

        if (!response.data.getProfileDiagram?.data) {
            throw Error('No diagram data returned');
        }

        return response.data.getProfileDiagram;
    } catch (error) {
        throw Error('Failed to get profile diagram: ' + error);
    }
};
