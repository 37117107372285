query GetFreeAreas($filter: FreeAreasFilter) {
  getFreeAreas(filter: $filter) {
    freeAreas {
      name
      id
      location
      perimeter
      color
      createdBy
      createdDate
      updatedBy
      updatedDate
      areaSize
      plan {
        description
      }
      type {
        groupname
        id
        txt
      }
      state {
        id
        groupname
        logicalTxt
        txt
        abbreviation
        numValue
        availabilityStartTs
        orderno
        numValue2
        description
        category
        value
        value2
        additionalData
      }
    }
  }
}
