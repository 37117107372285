import { getSplices } from '@apis/keycom';
import { FormConfig } from '../types';
import { addressGroup, planGroup, telecomAreaGroup } from '../groups';
import { Icons } from '@keypro/2nd-xp';
import { createComboField } from '../field-utils';

const config: FormConfig = {
    gqlType: 'Splice',
    model: 'splice',
    icon: <Icons.Splice />,
    groups: [
        {
            name: 'objectData',
            fields: [
                createComboField('owner', 'SPLICE_OWNER'),
                createComboField('renter', 'SPLICE_OWNER'),
                'identification',
                createComboField('usageState', 'SPLICE_STATE'),
                createComboField('type', 'SPLICE_TYPE'),
                createComboField('productName', 'SPLICE_PRODUCT_NAME'),
                createComboField('placement', 'SPLICE_PLACEMENT'),
                'attenuation',
                'installYear',
                {
                    name: 'remarks',
                    component: 'textarea',
                },
            ],
        },
        addressGroup,
        telecomAreaGroup,
        planGroup,
        {
            name: 'common',
            fields: [
                createComboField('locationAccuracy', 'LOCATION_ACCURACY'),
                createComboField('heightAccuracy', 'H_ACCURACY'),
                'historyDate',
                createComboField('mappingMethod', 'MAPPING_METHOD'),
                'surveyAmount',
                createComboField('safetyDistance', 'SAFETY_DISTANCE'),
                'mapper',
            ],
        },
    ],
    excludeFromSearch: [
        'address',
        'addressBind',
        'telecomArea',
        'telecomAreaBind',
        'plan',
        'planIncludeCompleted',
        'historyDate',
    ],
    functions: {
        get: getSplices,
    },
    queryCollection: 'splices',
};

export default config;
