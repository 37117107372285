import {
    Button,
    Checkbox,
    Coordinates,
    Icons,
    Input,
    MapContext,
    PositionOverlay,
    Select,
    SelectOptionItem,
    styled,
    useToast,
} from '@keypro/2nd-xp';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MapBrowserEvent } from 'ol';
import { t } from 'i18next';
import { useGetTxtConstants } from '@hooks/keycore/txtConstant';
import proj4 from 'proj4';

interface CoordinatesToolProps {
    isOpenTool?: boolean;
    onActionClickTool?: (isOpen: boolean) => void;
    onChangeCoordinateSystem?: (coordinateSystemName: string) => void;
}

/**
 * The CoordinatesTool component displays the coordinates tool.
 * @param isOpenTool - The state of the tool
 * @param onActionClickTool - The function to handle the tool action
 * @param onChangeCoordinateSystem - The function to handle the coordinate system change
 * @returns JSX.Element
 */
export const CoordinatesTool = ({
    isOpenTool,
    onActionClickTool,
    onChangeCoordinateSystem,
}: CoordinatesToolProps) => {
    const controller = useContext(MapContext)!;

    const [isHighlight, setIsHighlight] = useState(false);

    const [isShowCursorCoordinates, setIsShowCursorCoordinates] =
        useState(true);

    const [position, setPosition] = useState<Coordinates | undefined>(
        undefined,
    );

    const [coordinatesSystem, setCoordinatesSystem] = useState('4');

    const [positionInputX, setPositionInputX] = useState('');
    const [positionInputY, setPositionInputY] = useState('');
    const toast = useToast();

    const coordinatesSystemData = useGetTxtConstants({
        filter: {
            groupname: { eq: 'KEYCORE_IMPORT_SRS' },
        },
    });

    const coordinatesSystemList = useMemo(() => {
        return (
            coordinatesSystemData.data?.filter(
                (item) => item?.additionalData !== null,
            ) ?? []
        );
    }, [coordinatesSystemData]);

    const coordinatesSystemItems: SelectOptionItem[] = coordinatesSystemList
        .filter((item) => item?.orderno !== null)
        .map((item) => {
            return {
                label: item?.txt ?? '',
                value: item?.orderno?.toString() ?? '',
            };
        });

    const getSelectedCoordinateSystem = useCallback(
        (coordinatesSystemValue: string) => {
            return coordinatesSystemList.find(
                (item) => item?.orderno?.toString() === coordinatesSystemValue,
            );
        },
        [coordinatesSystemList],
    );

    const previousCoordinateSystem = useMemo(() => {
        return coordinatesSystemList.find(
            (item) => item?.txtValue?.toString() === 'EPSG:3067',
        );
    }, [coordinatesSystemList]);

    // make meters shown with 2 decimals (cm precision) and degrees with 6 decimals (10cm precision)
    const shownPrecisionByUnit = useCallback(
        (coordinatesSystemValue: string) => {
            const projString = proj4.defs(
                getSelectedCoordinateSystem(coordinatesSystemValue)?.txtValue ??
                    '',
            );
            if (projString?.units === 'm') {
                return 2;
            } else if (projString?.units === 'degrees') {
                return 6;
            }
            return 2;
        },
        [getSelectedCoordinateSystem],
    );

    const convertCurrentUserFromWSG84 = useMemo(() => {
        return (xCoordinate: number, yCoordinate: number) => {
            const temp = controller.convertFromWSG84([
                yCoordinate,
                xCoordinate,
            ]);
            return proj4(
                previousCoordinateSystem?.additionalData ?? '',
                getSelectedCoordinateSystem(coordinatesSystem)
                    ?.additionalData ?? '',
                temp,
            );
        };
    }, [
        controller,
        coordinatesSystem,
        getSelectedCoordinateSystem,
        previousCoordinateSystem?.additionalData,
    ]);

    const transformCoordinates = useMemo(() => {
        return (coordinates: Coordinates, toCoordinateSystem: string) => {
            if (
                !coordinates ||
                !getSelectedCoordinateSystem(toCoordinateSystem) ||
                !previousCoordinateSystem ||
                !getSelectedCoordinateSystem?.(toCoordinateSystem)
                    ?.additionalData ||
                !previousCoordinateSystem.additionalData
            ) {
                return;
            }

            const newCoordinates = proj4(
                previousCoordinateSystem?.additionalData ?? '',
                getSelectedCoordinateSystem?.(toCoordinateSystem)
                    ?.additionalData ?? '',
                coordinates,
            );

            return newCoordinates;
        };
    }, [getSelectedCoordinateSystem, previousCoordinateSystem]);

    useEffect(() => {
        const handleMouseMovePosition = (event: MapBrowserEvent<UIEvent>) => {
            setPosition([event.coordinate[0], event.coordinate[1]]);
        };

        if (isShowCursorCoordinates) {
            controller.on('mouseMove', handleMouseMovePosition);
        } else {
            controller.on('mouseClick', handleMouseMovePosition);
        }
        return () => {
            controller.off('mouseMove', handleMouseMovePosition);
        };
    }, [controller, isShowCursorCoordinates, position]);

    useEffect(() => {
        controller.on('mouseMove', (event: MapBrowserEvent<UIEvent>) => {
            const mousePosition: Coordinates = [
                event.coordinate[0],
                event.coordinate[1],
            ];
            if (mousePosition) {
                document.getElementById('coordinates')!.innerHTML =
                    transformCoordinates?.(
                        mousePosition,
                        coordinatesSystem,
                    )?.[0].toFixed(shownPrecisionByUnit(coordinatesSystem)) +
                    ', ' +
                    transformCoordinates(
                        mousePosition,
                        coordinatesSystem,
                    )?.[1].toFixed(shownPrecisionByUnit(coordinatesSystem));
            }
        });
    }, [
        controller,
        coordinatesSystem,
        shownPrecisionByUnit,
        transformCoordinates,
    ]);

    return (
        <>
            <StyledCoordinatesTool $isOpenTool={isOpenTool}>
                <StyledHeader>
                    <StyledTitle>{t('coordinateTooltip')}</StyledTitle>
                    <StyledCloseBtn
                        kind="ghost"
                        data-testid="close-coordinates-tool"
                        onClick={() => {
                            onActionClickTool?.(!isOpenTool);
                            setIsHighlight(false);
                        }}
                    >
                        <Icons.Cross2 />
                    </StyledCloseBtn>
                </StyledHeader>
                <StyledBody>
                    <StyledDivider />
                    <StyledSystem>
                        <StyledSystemLabel>
                            {t('displayCoordinateSystem')}
                        </StyledSystemLabel>
                        <div data-testid="coordinates-select">
                            <StyledSystemSelect
                                options={coordinatesSystemItems}
                                onChangeValue={(value) => {
                                    if (!Array.isArray(value)) {
                                        setCoordinatesSystem(value);
                                        setIsHighlight(false);
                                        onChangeCoordinateSystem?.(
                                            getSelectedCoordinateSystem?.(value)
                                                ?.txt ?? '',
                                        );
                                        if (position) {
                                            document.getElementById(
                                                'coordinates',
                                            )!.innerHTML =
                                                transformCoordinates?.(
                                                    position,
                                                    value,
                                                )?.[0].toFixed(
                                                    shownPrecisionByUnit(value),
                                                ) +
                                                ', ' +
                                                transformCoordinates(
                                                    position,
                                                    value,
                                                )?.[1].toFixed(
                                                    shownPrecisionByUnit(value),
                                                );
                                        }
                                    }
                                }}
                                defaultValue={coordinatesSystem}
                            />
                        </div>
                    </StyledSystem>
                    <StyledDivider />
                    <StyledLocation>
                        <StyledSystemLabelBtn>
                            <StyledCommonTitle>
                                {t('location')}
                            </StyledCommonTitle>
                            <StyledLocationBtn
                                kind="secondary"
                                iconPosition="left"
                                label={t('locateMe')}
                                onClick={() => {
                                    navigator.geolocation.getCurrentPosition(
                                        (position) => {
                                            const { latitude, longitude } =
                                                position.coords;
                                            const temp =
                                                convertCurrentUserFromWSG84(
                                                    latitude,
                                                    longitude,
                                                );
                                            setPositionInputX(
                                                temp[0]
                                                    .toFixed(
                                                        shownPrecisionByUnit(
                                                            coordinatesSystem,
                                                        ),
                                                    )
                                                    .toString(),
                                            );
                                            setPositionInputY(
                                                temp[1]
                                                    .toFixed(
                                                        shownPrecisionByUnit(
                                                            coordinatesSystem,
                                                        ),
                                                    )
                                                    .toString(),
                                            );
                                        },
                                    );
                                }}
                            >
                                <Icons.Locate />
                            </StyledLocationBtn>
                        </StyledSystemLabelBtn>
                        <StyledLocationTextField>
                            <StyledLocationInputLabel>
                                X
                            </StyledLocationInputLabel>
                            <StyledLocationInput
                                rightIcon={
                                    <div
                                        data-tooltip-top={t(
                                            'copyToClipboardTooltip',
                                        )}
                                        data-testid="copy-coordinate-x"
                                    >
                                        <Icons.Copy />
                                    </div>
                                }
                                onRightIconClick={() => {
                                    if (positionInputX && positionInputY) {
                                        navigator.clipboard
                                            .writeText(
                                                `${positionInputX}, ${positionInputY}`,
                                            )
                                            .then(() => {
                                                toast.show(
                                                    t('coordinatesCopied'),
                                                );
                                            })
                                            .catch((err) => {
                                                console.error(
                                                    t('copyError'),
                                                    err,
                                                );
                                            });
                                    }
                                }}
                                inputProps={{
                                    value: positionInputX,
                                    onChange: (
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        const newValue =
                                            e.target.value.toString();
                                        // Regex for float numbers
                                        if (
                                            /^-?(\d+(\.\d*)?|\.\d+)$/.test(
                                                newValue,
                                            ) ||
                                            newValue === ''
                                        ) {
                                            setPositionInputX(newValue);
                                        }
                                    },
                                }}
                            />
                        </StyledLocationTextField>
                        <StyledLocationTextField>
                            <StyledLocationInputLabel>
                                Y
                            </StyledLocationInputLabel>
                            <StyledLocationInput
                                rightIcon={
                                    <div
                                        data-tooltip-top={t(
                                            'copyToClipboardTooltip',
                                        )}
                                        data-testid="copy-coordinate-y"
                                    >
                                        <Icons.Copy />
                                    </div>
                                }
                                onRightIconClick={() => {
                                    if (positionInputX && positionInputY) {
                                        navigator.clipboard
                                            .writeText(
                                                `${positionInputX}, ${positionInputY}`,
                                            )
                                            .then(() => {
                                                toast.show(
                                                    t('coordinatesCopied'),
                                                );
                                            })
                                            .catch((err) => {
                                                console.error(
                                                    t('copyError'),
                                                    err,
                                                );
                                            });
                                    }
                                }}
                                inputProps={{
                                    value: positionInputY,
                                    onChange: (
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        const newValue =
                                            e.target.value.toString();
                                        // Regex for float numbers
                                        if (
                                            /^-?(\d+(\.\d*)?|\.\d+)$/.test(
                                                newValue,
                                            ) ||
                                            newValue === ''
                                        ) {
                                            setPositionInputY(newValue);
                                        }
                                    },
                                }}
                            />
                        </StyledLocationTextField>
                    </StyledLocation>
                    <StyledDivider />
                    <StyledInfo>
                        <StyledCommonTitle>
                            {t('defaultCoordinateSystem')}: ETRS89 /
                            ETRS-TM35FIN (EPSG:3067)
                        </StyledCommonTitle>
                        <StyledCoordinatesInfo>
                            <StyledCoordinates>
                                <StyledCoordinatesCaption>
                                    X
                                </StyledCoordinatesCaption>
                                <StyledCoordinatesValue>
                                    {position?.[0].toFixed(2)}
                                </StyledCoordinatesValue>
                            </StyledCoordinates>
                            <StyledCoordinates>
                                <StyledCoordinatesCaption>
                                    Y
                                </StyledCoordinatesCaption>
                                <StyledCoordinatesValue>
                                    {position?.[1].toFixed(2)}
                                </StyledCoordinatesValue>
                            </StyledCoordinates>
                        </StyledCoordinatesInfo>
                    </StyledInfo>
                    <StyledShowCursor>
                        <StyledShowCursorCheckBox data-testid="show-cursor-coordinates">
                            <StyledCheckBox
                                checked={isShowCursorCoordinates}
                                onChange={() =>
                                    setIsShowCursorCoordinates(
                                        !isShowCursorCoordinates,
                                    )
                                }
                            />
                            <StyledCheckBoxTitle
                                onClick={() =>
                                    setIsShowCursorCoordinates(
                                        !isShowCursorCoordinates,
                                    )
                                }
                            >
                                {t('showCursorCoordinates')}
                            </StyledCheckBoxTitle>
                        </StyledShowCursorCheckBox>
                        <StyledShowCursorDesc>
                            {t('showCursorCoordinatesDesc')}
                        </StyledShowCursorDesc>
                    </StyledShowCursor>
                    <StyledDivider />
                </StyledBody>
                <StyledFooter>
                    <StyledFooterButton
                        kind="secondary"
                        label={t('highlight')}
                        data-testid="highlight-button"
                        onClick={() => {
                            setIsHighlight(true);
                            if (positionInputX && positionInputY) {
                                const temp = proj4(
                                    getSelectedCoordinateSystem(
                                        coordinatesSystem,
                                    )?.additionalData ?? '',
                                    previousCoordinateSystem?.additionalData ??
                                        '',
                                    [
                                        parseFloat(positionInputX),
                                        parseFloat(positionInputY),
                                    ],
                                );
                                const mapExtent = controller.getExtent();
                                if (temp && mapExtent) {
                                    if (
                                        temp[0] < mapExtent[0] ||
                                        temp[0] > mapExtent[2] ||
                                        temp[1] < mapExtent[1] ||
                                        temp[1] > mapExtent[3]
                                    ) {
                                        controller.map
                                            .getView()
                                            .setCenter(temp);
                                    }
                                }
                            }
                        }}
                    />
                    <StyledFooterButton
                        kind="primary"
                        label={t('go')}
                        data-testid="go-button"
                        onClick={() => {
                            if (!positionInputX && !positionInputY) {
                                return;
                            }
                            const temp = proj4(
                                getSelectedCoordinateSystem(coordinatesSystem)
                                    ?.additionalData ?? '',
                                previousCoordinateSystem?.additionalData ?? '',
                                [
                                    parseFloat(positionInputX),
                                    parseFloat(positionInputY),
                                ],
                            );
                            controller.map.getView().setCenter(temp);
                        }}
                    />
                </StyledFooter>
            </StyledCoordinatesTool>
            <PositionOverlay
                data-testid="highlight-overlay-coordinates-tool"
                position={
                    isHighlight && positionInputX && positionInputY
                        ? (() => {
                              const coords = proj4(
                                  getSelectedCoordinateSystem(coordinatesSystem)
                                      ?.additionalData ?? '',
                                  previousCoordinateSystem?.additionalData ??
                                      '',
                                  [
                                      parseFloat(positionInputX),
                                      parseFloat(positionInputY),
                                  ],
                              );
                              // Check the number of elements
                              if (coords.length === 2) {
                                  return coords as [number, number];
                              }
                              throw new Error(
                                  'Invalid coordinates returned from proj4',
                              );
                          })()
                        : [0, 0]
                }
                positioning="center-center"
            >
                <Icons.LocationMark style={{ width: 40, height: 40 }} />
            </PositionOverlay>
        </>
    );
};

const StyledCoordinatesTool = styled.div<{ $isOpenTool?: boolean }>`
    box-sizing: border-box;
    border-radius: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    position: absolute;
    background-color: ${(props) => props.theme.colors.neutral['10']};
    border: 1px solid ${(props) => props.theme.colors.neutral['50']};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    width: 300px;
    bottom: 60px;
    right: -4px;
    z-index: 1;
    display: ${(props) => (props.$isOpenTool ? 'block' : 'none')};
`;

const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 8px;
    padding-bottom: 8px;
`;

const StyledTitle = styled.span`
    font: ${(props) => props.theme.fonts['16px Bold']};
    color: ${(props) => props.theme.colors.neutral['100']};
`;

const StyledCloseBtn = styled(Button)`
    color: ${(props) => props.theme.colors.neutral['90']};
    width: 32px;
    height: 32px;
    & > svg {
        width: 16px;
        height: 16px;
    }
`;

const StyledBody = styled.div`
    padding-left: 16px;
    padding-right: 16px;
`;

const StyledSystem = styled.div`
    padding-top: 14px;
    padding-bottom: 14px;
`;

const StyledSystemLabel = styled.div`
    font: ${(props) => props.theme.fonts['14px Bold']};
    color: ${(props) => props.theme.colors.neutral['100']};
    padding-bottom: 8px;
`;

const StyledSystemSelect = styled(Select)`
    width: 100%;
`;

const StyledLocation = styled.div`
    padding-top: 16px;
    padding-bottom: 16px;
    display: grid;
    gap: 8px;
`;

const StyledSystemLabelBtn = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledCommonTitle = styled.div`
    padding-top: 6px;
    padding-bottom: 6px;
    font: ${(props) => props.theme.fonts['14px Bold']};
    color: ${(props) => props.theme.colors.neutral['100']};
`;

const StyledLocationBtn = styled(Button)`
    & > svg {
        color: ${(props) => props.theme.colors.accents.blue['10']};
    }
`;

const StyledLocationTextField = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledLocationInputLabel = styled.span`
    font: ${(props) => props.theme.fonts['14px Regular']};
    color: ${(props) => props.theme.colors.neutral['90']};
`;

const StyledLocationInput = styled(Input)`
    width: 192px;
    & svg {
        cursor: pointer;
        color: ${(props) => props.theme.colors.neutral['70']};
        &:hover {
            color: ${(props) => props.theme.colors.neutral['90']};
        }
    }
`;

const StyledInfo = styled.div`
    padding-top: 16px;
    padding-bottom: 16px;
`;

const StyledCoordinatesInfo = styled.div`
    display: flex;
    gap: 16px;
`;

const StyledCoordinates = styled.div`
    display: flex;
    gap: 8px;
`;

const StyledCoordinatesCaption = styled.div`
    font: ${(props) => props.theme.fonts['14px Bold']};
    color: ${(props) => props.theme.colors.neutral['80']};
`;

const StyledCoordinatesValue = styled.div`
    font: ${(props) => props.theme.fonts['14px Regular']};
    color: ${(props) => props.theme.colors.neutral['90']};
`;

const StyledShowCursor = styled.div`
    padding-bottom: 16px;
`;

const StyledShowCursorCheckBox = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
`;

const StyledCheckBox = styled(Checkbox)`
    border-radius: 4px;
    width: 16px;
    height: 16px;
`;

const StyledCheckBoxTitle = styled.span`
    font: ${(props) => props.theme.fonts['14px Regular']};
    color: ${(props) => props.theme.colors.neutral['100']};
    padding-top: 6px;
    padding-bottom: 6px;
`;

const StyledShowCursorDesc = styled.div`
    font: ${(props) => props.theme.fonts['12px Regular']};
    color: ${(props) => props.theme.colors.neutral['80']};
    padding-left: 32px;
`;

const StyledFooter = styled.div`
    padding: 16px 16px 8px 16px;
    display: flex;
    gap: 8px;
`;

const StyledFooterButton = styled(Button)`
    width: 130px;
`;

const StyledDivider = styled.div`
    height: 2px;
    background-color: ${(props) => props.theme.colors.neutral['20']};
    width: 100%;
`;
