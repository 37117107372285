import { useSearch } from '@hooks/keycore/search';
import { handleOutsideClick } from '@components/utils';
import {
    Button,
    Icons,
    Input,
    PositionOverlay,
    styled,
    Coordinates,
    useIsMobile,
} from '@keypro/2nd-xp';
import {
    useLeftMenu,
    useSearchStore,
    useProductStore,
    useMobileMenu,
} from '@stores';
import { t } from 'i18next';
import {
    ChangeEvent,
    HTMLAttributes,
    useEffect,
    useRef,
    useState,
} from 'react';
import { FullSearch } from './FullSearch';
import { QuickSearchResults } from './search-components/QuickSearchResult';

/**
 * Delay in milliseconds before search is performed after user has stopped typing.
 * This delay helps to reduce the number of search requests when user is typing.
 */
const SEARCH_DELAY = 250;

const SearchInput = styled(Input)`
    background-color: ${(props) => props.theme.colors.neutral[20]};
    border: 0;
    width: 332px;

    &:focus,
    &:focus-within {
        box-shadow: none;
    }

    &.active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

const SearchContainer = styled.div`
    position: relative;
    width: 100%;
`;

const SearchButton = styled(Button)`
    width: 24px;
    height: 24px;

    svg {
        width: 16px;
        height: 16px;

        path {
            fill: ${(props) => props.theme.colors.neutral[80]};
            stroke: ${(props) => props.theme.colors.neutral[80]};
        }
    }
`;

const CloseButton = styled(Button)`
    background-color: ${(props) => props.theme.colors.neutral[50]};
    width: 20px;
    height: 20px;
    border-radius: 50%;

    svg {
        width: 9px;
        height: 9px;

        path {
            stroke-width: 4px;
            color: ${(props) => props.theme.colors.neutral[90]};
        }
    }

    &:hover {
        background-color: ${(props) =>
            props.theme.colors.neutral[60]} !important;
    }
`;

const EndButtons = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const placeHolderText = (product: string) => {
    switch (product) {
        case 'keycom':
            return t('searchKeycom');
        case 'keyaqua':
            return t('searchKeyaqua');
        default:
            return '';
    }
};

/**
 * Combined search field for both quick and full search.
 */
const SearchField = (props: HTMLAttributes<HTMLDivElement>) => {
    const {
        searchTerm: searchValue,
        setSearchTerm: setSearchValue,
        highlightPosition,
        isHighlighted,
        setIsHighlighted,
        searchFieldActive: active,
        setSearchFieldActive: setActive,
        locationFilter,
        setIsFullsearch,
    } = useSearchStore();
    const [displayValue, setDisplayValue] = useState('');
    const [timeoutId, setTimeoutId] = useState<number | null>(null);
    const ref = useRef<HTMLDivElement>(null);
    const isMobile = useIsMobile();
    const menuContext = isMobile ? useMobileMenu : useLeftMenu;
    const { menuContentId, setMenuContent, isMenuOpen, toggleMenu } =
        menuContext();
    const { product } = useProductStore();
    const { setMenuHeight } = useMobileMenu();

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDisplayValue(event.target.value);

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        const newTimeoutId = window.setTimeout(() => {
            setSearchValue(event.target.value);
        }, SEARCH_DELAY);

        setTimeoutId(newTimeoutId);
    };

    const onFocus = () => {
        setActive(true);
    };

    const showFullSearch = () => {
        setActive(false);
        // Show full search
        setMenuContent(
            `FullSearch-${searchValue}`,
            <FullSearch
                searchResults={results}
                searchTerm={searchValue}
                setQuickSearchIsHighlighted={setIsHighlighted}
            />,
        );
        setIsFullsearch(true);
        if (isMobile) setMenuHeight(75);
    };

    const { data: results, isFetching: searching } = useSearch(searchValue);

    useEffect(() => {
        if (
            isMobile &&
            results &&
            !searching &&
            menuContentId !== `QuickSearch-${searchValue}` &&
            active
        ) {
            setMenuContent(
                `QuickSearch-${searchValue}`,
                <QuickSearchResults />,
            );
            setIsFullsearch(false);
        }
    }, [
        isMobile,
        results,
        searching,
        setMenuContent,
        menuContentId,
        searchValue,
        active,
        setIsFullsearch,
    ]);

    const endButtons: JSX.Element[] = [
        <SearchButton
            key="doSearch"
            kind="ghost"
            onClick={showFullSearch}
            data-tooltip={t('openFullSearch')}
            data-testid="open-full-search-button"
        >
            <Icons.Search />
        </SearchButton>,
    ];

    const closeSearch = () => {
        // Reactivate search field on the next animation frame after closing results
        requestAnimationFrame(() =>
            ref.current?.querySelector('input')?.focus(),
        );

        if (isMenuOpen && !isMobile) {
            toggleMenu();
        }

        setActive(false);
        setDisplayValue('');
        setSearchValue('');
        setIsHighlighted(false);
        locationFilter?.clearIfLayerExist();
    };

    if (searchValue || isMenuOpen) {
        endButtons.push(
            <CloseButton
                key="clearSearch"
                kind="ghost"
                data-tooltip={t('closeSearch')}
                onClick={closeSearch}
                data-testid="close-search-button"
            >
                <Icons.Cross2 />
            </CloseButton>,
        );
    }

    // Hide results when clicking outside of search field or results component
    useEffect(() => {
        if (!isMobile) return handleOutsideClick(ref, setActive, active);
    }, [ref, active, setActive, isMobile]);

    // Clear highlight when search value changes
    useEffect(() => {
        setIsHighlighted(false);
    }, [searchValue, setIsHighlighted]);

    return (
        <SearchContainer ref={ref} {...props}>
            <PositionOverlay
                position={highlightPosition as Coordinates}
                positioning="bottom-center"
                style={{
                    display: isHighlighted ? 'block' : 'none',
                }}
            >
                <Icons.LocationMark style={{ width: 40, height: 40 }} />
            </PositionOverlay>
            <SearchInput
                inputProps={{
                    value: displayValue,
                    placeholder: placeHolderText(product ?? ''),
                    onChange: onChange,
                    onFocus: onFocus,
                    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === 'Enter') {
                            showFullSearch();

                            // Blur the input field to close the virtual keyboard
                            isMobile && (e.target as HTMLInputElement).blur();
                        }
                    },
                }}
                rightIcon={<EndButtons>{endButtons}</EndButtons>}
                className={active ? 'active' : ''}
            />
            {active && !isMobile && <QuickSearchResults />}
        </SearchContainer>
    );
};

export default SearchField;
