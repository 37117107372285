query Search($term: String!) {
  search(term: $term) {
    metadata {
      totalCount
    }
    searchResults {
      id
      location
      identification
      modelName
      modelIdentity
      angle
    }
  }
}
