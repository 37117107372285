import {
    Button,
    Icons,
    StateHint,
    styled,
    Toolbar,
    useIsMobile,
} from '@keypro/2nd-xp';
import { t } from 'i18next';
import { useState } from 'react';
import { CoordinatesTool } from './CoordinatesTool';

const Coordinates = styled.div`
    height: 32px;
    display: flex;
    gap: 24px;
    margin-right: 8px;
    position: relative;
`;

const CoordinateItem = styled.span`
    margin: auto;
`;

const CoordinateBtn = styled(Button)`
    width: 36px;
    height: 32px;
    & > svg {
        width: 20px;
        height: 20px;
    }
`;

const FooterLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: linear-gradient(
        45deg,
        rgba(29, 33, 37, 1),
        rgba(19, 19, 22, 1)
    );
    border-radius: 8px;
`;

const AppFooter = (): JSX.Element => {
    const [isCoordinatesToolOpen, setIsCoordinatesToolOpen] = useState(false);
    const [coordinateSystemName, setCoordinateSystemName] = useState('');
    const isMobile = useIsMobile();

    if (isMobile) return <></>;

    return (
        <Toolbar>
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                <FooterLogo>
                    <Icons.Logo />
                </FooterLogo>
                <StateHint />
            </div>
            <Coordinates style={{ justifyContent: 'end' }}>
                <CoordinateItem data-tooltip-top={t('srsTooltip')} id="srs">
                    {coordinateSystemName === ''
                        ? 'ETRS89 / ETRS-TM35FIN (EPSG:3067)'
                        : coordinateSystemName}
                </CoordinateItem>
                <CoordinateItem
                    data-tooltip-top={t('coordinateTooltip')}
                    id="coordinates"
                    data-testid="coordinates"
                >
                    55.750655, 37.653668, 18
                </CoordinateItem>
                <CoordinateItem>
                    <CoordinateBtn
                        kind={isCoordinatesToolOpen ? 'primary' : 'ghost'}
                        id="coordinate-button"
                        data-tooltip-left={t('srsTooltip')}
                        onClick={() => {
                            setIsCoordinatesToolOpen(!isCoordinatesToolOpen);
                        }}
                    >
                        <Icons.Settings2 />
                    </CoordinateBtn>
                </CoordinateItem>
                <CoordinatesTool
                    isOpenTool={isCoordinatesToolOpen}
                    onActionClickTool={setIsCoordinatesToolOpen}
                    onChangeCoordinateSystem={setCoordinateSystemName}
                />
            </Coordinates>
        </Toolbar>
    );
};

export default AppFooter;
