import { GetPlacementScalesQuery } from '@generated';
import { sendGraphQLRequest } from '@utils';

import GetPlacementScales from './queries/GetPlacementScales.graphql';

/**
 * Gets the placement scale options for MapController.
 * @returns {Promise<number[]>} The placement scales.
 * @throws {Error} If the request fails.
 */
export const fetchPlacementScales = async (): Promise<number[]> => {
    try {
        const response =
            await sendGraphQLRequest<GetPlacementScalesQuery>(
                GetPlacementScales,
            );

        const data = response.data.getTxtConstants?.txtConstants;

        if (data) {
            return data.map((d) => d!.numValue as number);
        } else {
            throw Error('No data returned');
        }
    } catch (error) {
        throw Error('Failed to get placement scales: ' + error);
    }
};
