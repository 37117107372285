import { getConduits } from '@apis/keycom';
import { FormConfig } from '../types';
import { altDataSourceGroup, planGroup, telecomAreaGroup } from '../groups';
import { Icons } from '@keypro/2nd-xp';
import { createComboField } from '../field-utils';

const config: FormConfig = {
    gqlType: 'Conduit',
    model: 'conduit',
    icon: <Icons.Fiber />,
    groups: [
        {
            name: 'objectData',
            fields: [
                'identification',
                createComboField('usageState', 'CONDUIT_STATE'),
                'length',
                createComboField('conduitType', 'CONDUIT_TYPE'),
                createComboField('measure', 'CABLE_MEASURE'),
                createComboField('profileStatus', 'PROFILE_STATUS'),
                createComboField('surfaceType', 'CONDUIT_SURFACE_TYPE'),
                { name: 'remarks', component: 'textarea' },
            ],
        },
        telecomAreaGroup,
        planGroup,
        {
            name: 'common',
            fields: [
                createComboField('locationAccuracy', 'LOCATION_ACCURACY'),
                createComboField('heightAccuracy', 'H_ACCURACY'),
                createComboField('mappingMethod', 'MAPPING_METHOD'),
                'surveyAmount',
                createComboField('safetyDistance', 'SAFETY_DISTANCE'),
                'mapper',
            ],
        },
        altDataSourceGroup,
    ],
    excludeFromSearch: [
        'telecomArea',
        'telecomAreaBind',
        'plan',
        'planIncludeCompleted',
        'historyDate',
        'sourceKey',
    ],
    functions: {
        get: getConduits,
    },
    queryCollection: 'conduits',
};

export default config;
