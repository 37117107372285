import { FormField } from './types';

/**
 * Create a combobox field with a filter for the given group name.
 * @param name The field name.
 * @param groupname The group name to filter by.
 * @param translationKey The translation key for the field label (default = field name).
 * @param isMulti Whether the combobox should support multiple selections.
 * @returns The combobox field.
 */
export const createComboField = (
    name: string,
    groupname: string,
    isMulti?: boolean,
    translationKey?: string,
): FormField => ({
    name,
    filter: { groupname: { eq: groupname } },
    component: isMulti ? 'combobox-multi' : 'combobox',
    translationKey: translationKey,
});

/**
 * Create a range-number field with an optional translation key.
 * @param name The field name.
 * @param translationKey The translation key for the field label (default = field name).
 * @returns The range-number field.
 */
export const createRangeField = (
    name: string,
    translationKey?: string,
): FormField =>
    translationKey
        ? {
              name,
              component: 'range-number',
              translationKey,
          }
        : {
              name,
              component: 'range-number',
          };
