import { Icons, styled } from '@keypro/2nd-xp';
import { useMobileMenu } from '@stores';
import { useCallback, useEffect, useRef } from 'react';

const StyledMobileMenuContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 1;
    bottom: 0;
    pointer-events: auto;
    background-color: ${(props) => props.theme.colors.neutral[20]};
    transition: height 250ms;
    overflow-y: auto;
`;

const StyledDragger = styled.div`
    z-index: 101;
    position: absolute;
    top: -4px;
    cursor: ns-resize;
    & > svg {
        width: 20px;
        height: 20px;
        transform: rotate(90deg);
        & path[stroke] {
            stroke: ${(props) => props.theme.colors.neutral['70']};
            stroke-width: 4px;
        }
    }
`;

const StyledOverlayContainer = styled.div`
    z-index: 2;
    position: absolute;
    width: 100%;
`;

/**
 * The `AppMobileMenus` component provides a single resizable menu system for mobile
 * devices. Renders mobile menu content and an optional overlay. Supports resizing of
 * the menu by dragging a handle and snaping to predefined values (15%, 50%, 75%, 100%).
 * @returns The `AppMobileMenus` component for mobile devices.
 */

const AppMobileMenus = (): JSX.Element => {
    const {
        isMenuOpen,
        menuHeight,
        menuOverlayContent,
        menuContent,
        setMenuHeight,
    } = useMobileMenu();
    const draggerRef = useRef<HTMLDivElement>(null);

    const startDrag = useCallback(
        (startY: number) => {
            const onMove = (moveEvent: MouseEvent | TouchEvent) => {
                const currentY =
                    'clientY' in moveEvent
                        ? moveEvent.clientY
                        : moveEvent.touches[0].clientY;
                const deltaY = currentY - startY;
                const newHeight =
                    menuHeight - (deltaY / window.innerHeight) * 100;
                const allowedHeights = [15, 50, 75, 100];
                const closestHeight = allowedHeights.reduce(
                    (prev, curr) =>
                        Math.abs(curr - newHeight) < Math.abs(prev - newHeight)
                            ? curr
                            : prev,
                    allowedHeights[0],
                );
                setMenuHeight(closestHeight);
            };

            const onEnd = () => {
                window.removeEventListener('mousemove', onMove);
                window.removeEventListener('mouseup', onEnd);
                window.removeEventListener('touchmove', onMove);
                window.removeEventListener('touchend', onEnd);
            };

            window.addEventListener('mousemove', onMove);
            window.addEventListener('mouseup', onEnd);
            window.addEventListener('touchmove', onMove);
            window.addEventListener('touchend', onEnd);
        },
        [menuHeight, setMenuHeight],
    );

    useEffect(() => {
        const draggerElement = draggerRef.current;
        const nonPassiveTouchStart = (event: TouchEvent | MouseEvent) => {
            event.preventDefault(); // Prevent default browser behavior (the drag down to refresh)
            startDrag(
                'clientY' in event ? event.clientY : event.touches[0].clientY,
            );
        };

        if (draggerElement) {
            draggerElement.addEventListener(
                'touchstart',
                nonPassiveTouchStart,
                {
                    passive: false,
                },
            );
            draggerElement.addEventListener('mousedown', nonPassiveTouchStart);
        }

        return () => {
            if (draggerElement) {
                draggerElement.removeEventListener(
                    'touchstart',
                    nonPassiveTouchStart,
                );
                draggerElement.removeEventListener(
                    'mousedown',
                    nonPassiveTouchStart,
                );
            }
        };
    }, [startDrag, isMenuOpen]);

    if (!isMenuOpen) return <></>;

    return (
        <>
            {menuOverlayContent && (
                <StyledOverlayContainer
                    style={{
                        bottom: `${menuHeight}%`,
                    }}
                >
                    {menuOverlayContent}
                </StyledOverlayContainer>
            )}
            <StyledMobileMenuContainer
                id="menu-container"
                style={{
                    height: `${menuHeight}%`,
                }}
            >
                <StyledDragger
                    data-testid="mobile-menu-dragger"
                    ref={draggerRef}
                >
                    <Icons.Divider />
                </StyledDragger>
                {menuContent}
            </StyledMobileMenuContainer>
        </>
    );
};

export default AppMobileMenus;
