import { FormGroup } from '@form-configs';
import { createComboField } from './field-utils';

/**
 * Common location and mapping related fields.
 */
export const commonGroup: FormGroup = {
    name: 'common',
    fields: [
        createComboField('locationAccuracy', 'LOCATION_ACCURACY'),
        createComboField(
            'heightAccuracy',
            'H_ACCURACY',
            false,
            'heightAccuracy',
        ),
        'historyDate',
        createComboField('mappingMethod', 'MAPPING_METHOD'),
        'surveyAmount',
        'mapper',
    ],
};

/**
 * Plan field with "include completed" checkbox.
 */
export const planGroup: FormGroup = {
    name: 'planGroup',
    fields: [
        'plan',
        {
            name: 'planIncludeCompleted',
            component: 'checkbox',
            custom: true,
        },
    ],
};

/**
 * Telecom area field with "bind automatically" checkbox.
 */
export const telecomAreaGroup: FormGroup = {
    name: 'telecomAreaGroup',
    fields: [
        'telecomArea',
        {
            name: 'telecomAreaBind',
            component: 'checkbox',
            custom: true,
        },
    ],
};

/**
 * Data source fields.
 */
export const dataSourceGroup = {
    name: 'dataSource',
    fields: [
        createComboField('sourceSystem', 'SOURCE_SYSTEM'),
        'sourceSystemKey',
    ],
};

/**
 * Data source fields (alternate).
 */
export const altDataSourceGroup = {
    name: 'dataSource',
    fields: [
        createComboField('sourceSystem', 'SOURCE_SYSTEM'),
        { name: 'sourceKey', translationKey: 'sourceSystemKey' },
    ],
};

/**
 * Address fields.
 */
export const addressGroup: FormGroup = {
    name: 'addressGroup',
    fields: [
        { name: 'address', component: 'address' },
        {
            name: 'addressBind',
            component: 'checkbox',
            custom: true,
        },
    ],
};
