import { Dispatch, RefObject, SetStateAction } from 'react';
import { SelectOptionItem } from '@keypro/2nd-xp';
import Polygon from 'ol/geom/Polygon';
import { Coordinate } from 'ol/coordinate';
import { FormGroup, getFormConfig } from '@form-configs';
import { GraphQLTypeMap } from '@apis/introspection';
import {
    createComboField,
    createRangeField,
} from 'src/form-configs/field-utils';

/**
 * Adds a click event listener to the document that deactivates the component
 * when clicking outside of it. Should be used in a useEffect hook.
 * @param ref The reference to the component
 * @param setActive The setter for the active state
 * @param isActive The active state
 * @returns The cleanup function for useEffect
 */
export const handleOutsideClick = (
    ref: RefObject<HTMLElement>,
    setActive: Dispatch<SetStateAction<boolean>> | ((active: boolean) => void),
    isActive: boolean,
) => {
    const onClick = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setActive(false);
        }
    };

    if (isActive) {
        document.addEventListener('click', onClick);
    } else {
        document.removeEventListener('click', onClick);
    }

    return () => {
        document.removeEventListener('click', onClick);
    };
};

/**
 * Returns the icon for the given layer name.
 * @param layerName Name of the layer
 */
export const infoToolsLayers = {
    freearea: 'freearea',
    address: 'keygwt_address',
    telecompremise: 'prem',
    cable: 'cable',
    splice: 'splice',
    freeline: 'freeline',
    manhole: 'manhole',
    conduit: 'conduit',
};

// Predefined object types for search filter dropdown.
// NOTE: These should be made configurable in the future.
export const objectTypes: SelectOptionItem[] = [
    {
        value: 'freearea',
        label: 'Areas',
    },
    {
        value: 'address',
        label: 'Addresses',
    },
    {
        value: 'telecompremise',
        label: 'Premises',
    },
    {
        value: 'cable',
        label: 'Cables',
    },
    {
        value: 'splice',
        label: 'Splices',
    },
    {
        value: 'freeline',
        label: 'Lines',
    },
    {
        value: 'manhole',
        label: 'Manholes',
    },
    {
        value: 'conduit',
        label: 'Conduits',
    },
];

/**
 * Creates a rectangular polygon around the given point.
 * @param center Center of the polygon.
 * @param size Desired size of the polygon.
 * @returns Polygon around the given point.
 */
export const pointToPolygon = (center: Coordinate, size: number): Polygon => {
    const [x, y] = center;
    const offset = size / 2;
    const coords = [
        [x - offset, y - offset],
        [x + offset, y - offset],
        [x + offset, y + offset],
        [x - offset, y + offset],
        [x - offset, y - offset],
    ];

    return new Polygon([coords]);
};

export const getFilteredConfig = (
    selectedObjectType: string,
    selectedGroups: string[],
    type: string | null,
    getFieldType: (field: string) => string,
) => {
    const groups: FormGroup[] = [];
    if (type && selectedObjectType) {
        const newGroup: FormGroup = {
            name: type,
            fields: [],
        };
        getFormConfig(type).groups.forEach((group) => {
            group.fields.forEach((field) => {
                if (
                    typeof field === 'string' &&
                    selectedGroups.includes(field)
                ) {
                    const componentType = getFieldType(field);
                    if (componentType === 'text') {
                        newGroup.fields.push(field);
                    } else if (componentType === 'number') {
                        newGroup.fields.push(createRangeField(field));
                    }
                } else if (
                    typeof field !== 'string' &&
                    selectedGroups.includes(field.name)
                ) {
                    const componentType = getFieldType(field.name);
                    if (componentType === 'combobox') {
                        newGroup.fields.push(
                            createComboField(
                                field.name,
                                field.filter?.groupname.eq as string,
                                true,
                            ),
                        );
                    }
                }
            });
        });
        groups.push(newGroup);
    }
    return groups;
};

export const SEARCHSTRING = 'Search';

export const getType = (gqlType: string, types: GraphQLTypeMap) => {
    let type;

    if (gqlType.endsWith(SEARCHSTRING)) {
        const tempGqlType = gqlType.slice(0, -SEARCHSTRING.length);
        type = types[tempGqlType];
    } else {
        type = types[gqlType];
    }

    if (!type) {
        throw new Error(`Type not found: ${gqlType}`);
    }
    return type;
};
