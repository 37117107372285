// Libraries
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// 2nd-xp
import { DateInput, DateInputProps } from '@keypro/2nd-xp';

interface FormDateInputProps extends DateInputProps {
    /** Initial date to show on input */
    initialValue?: Date;
    onChange?: (date: Date | undefined) => void;
}

/**
 * A variant of DateInput component that can be used in a form.
 */
const FormDateInput = (props: FormDateInputProps): JSX.Element => {
    const { initialValue, onChange, ...rest } = props;
    const [value, setValue] = useState<Date | undefined>();
    const { i18n } = useTranslation();
    const locale = i18n.language;

    return (
        <DateInput
            {...rest}
            locale={locale}
            selectedDate={value ?? initialValue}
            onDateChange={(date) => {
                setValue(date);
                onChange?.(date);
            }}
        />
    );
};

export default FormDateInput;
