import {
    defaultTopActions,
    defaultMiddleActions,
    defaultBottomActions,
    DIVIDER,
    FormConfig,
    FormContext,
    FormData,
    ActionMenuItem,
} from '@form-configs';
import { Button, MapContext, styled } from '@keypro/2nd-xp';
import { useCenterMenu, useLeftMenu, useRightMenu } from '@stores';
import { t } from 'i18next';
import { HTMLAttributes, useContext } from 'react';

/**
 * The props of ActionsContainer component.
 * @param model The model of the object.
 */
export interface ActionsContainerProps extends HTMLAttributes<HTMLDivElement> {
    /** Is the menu open? */
    isOpen: boolean;
    /** Function for toggling the menu on/off */
    toggleMenu: (isOpen: boolean) => void;
    /** Form configuration */
    form: FormConfig;
    /** Form data */
    data: FormData;
}

/**
 * The ActionsContainer component displays action list.
 * @param model The model of the object.
 * @returns The InfoObjectContainer component
 */
export const ActionsContainer = ({
    isOpen,
    toggleMenu,
    form,
    data,
    ...rest
}: ActionsContainerProps) => {
    const formActions: ActionMenuItem[] = form.actions ?? [
        ...defaultTopActions,
        ...defaultMiddleActions,
        ...defaultBottomActions,
    ];
    const { setMenuContent: setLeftContent } = useLeftMenu();
    const { setMenuContent: setCenterContent } = useCenterMenu();
    const { setMenuContent: setRightContent } = useRightMenu();

    const formContext: FormContext = {
        form,
        data,
        setLeftContent: setLeftContent,
        setCenterContent: setCenterContent,
        setRightContent: setRightContent,
        mapController: useContext(MapContext)!,
    };

    const actionMenuItems = formActions.map((item, index) => {
        let actionId;
        let enabled = true;

        if (item == DIVIDER) {
            actionId = `divider`;
        } else if (typeof item === 'string') {
            actionId = `header-${item}`;
        } else {
            actionId = item.translationKey;

            if (item.shouldBeEnabled) {
                enabled = item.shouldBeEnabled(formContext);
            }
        }

        actionId = actionId.replace(' ', '').toLocaleLowerCase();
        const key = `${form.model}-${actionId}-${index}`;

        if (item === DIVIDER) {
            return <StyledDivider key={key} />;
        } else if (typeof item === 'string') {
            return <StyledTitle key={key}>{t(item)}</StyledTitle>;
        } else {
            return (
                <StyledAction key={key}>
                    <Button
                        iconPosition="left"
                        kind="ghost"
                        label={t(item.translationKey)}
                        onClick={() => {
                            item.onClick(formContext);
                            toggleMenu(false);
                        }}
                        disabled={!enabled}
                        data-testid={`info-object-action-${index}`}
                    >
                        {item.icon}
                    </Button>
                </StyledAction>
            );
        }
    });

    return (
        <StyledActionsContainer $isOpen={isOpen} {...rest}>
            {actionMenuItems}
        </StyledActionsContainer>
    );
};

const StyledActionsContainer = styled.div<{ $isOpen?: boolean }>`
    display: ${(props) => (props.$isOpen ? 'block' : 'none')};
    width: 256px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.neutral['50']};
    background-color: ${(props) => props.theme.colors.neutral['10']};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    position: absolute;
    z-index: 100;
    top: 34px;
    right: -10px;

    padding: 8px 0;

    &::after {
        display: inline-block;
        width: 14px;
        height: 4px;
        position: absolute;

        transform: translate(-50%, -10px);
        top: auto;
        left: 90%;
        bottom: 100%;

        content: url('data:image/svg+xml;utf8,<svg viewBox="0 0 15 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 2.00024L1.5 6.00024L13.5 6.00024L7.5 2.00024Z" fill="${(
            props,
        ) =>
            encodeURIComponent(
                props.theme.colors.neutral['10'],
            )}" /><path d="M0.5 5.50024L1.5 5.50024L7.5 1.50024L13.5 5.50024L14.5 5.50024" stroke="${(
            props,
        ) => encodeURIComponent(props.theme.colors.neutral['50'])}" /></svg>');
    }
`;

const StyledAction = styled.div`
    padding: 0 8px;
    & > button {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        ${(props) => props.theme.fonts['14px Regular']};
        color: ${(props) => props.theme.colors.neutral['90']};
        & > svg {
            width: 20px;
            height: 20px;
        }
    }
`;

const StyledDivider = styled.div`
    height: 2px;
    background-color: ${(props) => props.theme.colors.neutral['20']};
    width: 100%;
    margin: 8px 16px;
`;

const StyledTitle = styled.p`
    margin: 16px 16px 8px 16px;
    ${(props) => props.theme.fonts['12px Medium Uppercase']};
    color: ${(props) => props.theme.colors.neutral['80']};
`;
