import {
    Button,
    Icons,
    MapContext,
    SideMenu,
    TabSelector,
    styled,
    useToast,
} from '@keypro/2nd-xp';
import { PrintMenuSettingPart } from './PrintMenuSettingPart';
import { PrintMenuLegendPart } from './PrintMenuLegendPart';
import { t } from 'i18next';
import { useContext, useRef, useState } from 'react';
import { PrintArgs, PageInfo } from '@generated';
import { quickPrint } from '@apis/keycore/quickPrint';
import { useCenterMenu, useRightMenu } from '@stores';
import { generateLayerUrl, getPrintAreaGeojson } from './utils';
import { useGetMapConfig } from '@hooks/map';
import { PrintMenuThemeLegendPart } from './PrintMenuThemeLegendPart';
import { buildTenantURL } from '@utils';

export const PrintMenuContainer = () => {
    const [loading, setLoading] = useState(false);
    const { setMenuContent: setRightMenuContent } = useRightMenu();
    const { setMenuContent: setCenterMenuContent } = useCenterMenu();
    const controller = useContext(MapContext)!;
    const toast = useToast();
    const pageInfoRef = useRef<PageInfo>({});
    const map = controller.map;
    const { data: mapConfig } = useGetMapConfig();

    const closePrintMenus = () => {
        setRightMenuContent(t('print'), <></>);
        setCenterMenuContent(t('print'), <></>);
    };

    /**
     * Get the active layers configuration. Somehow some layer_configs have type
     * 'TMS' despite having 'WMS' in the url so we have to give it the correct type
     * @returns {Array} An array of active layers configuration.
     */
    const getActiveLayerConfigurations = () => {
        if (!mapConfig) throw new Error('No mapConfig data found');

        const notSupportedLayers = ['combo_keyrns'];
        const activeLayerNames = controller.layers
            .getActiveLayerNames()
            .filter((n) => !notSupportedLayers.includes(n));

        return mapConfig.layerConfigs
            .filter((lc) => activeLayerNames.includes(lc.name))
            .map((lc) => ({
                layerName: lc.name,
                layerType: lc.type === 'TMS' ? 'WMS' : lc.type,
                layerOrder: lc.order,
                layers: lc.paramsV2.layers?.toString() || lc.paramsV2.layername,
                url: generateLayerUrl(lc),
            }));
    };

    /**
     * Begin the print process by preparing and sending print configuration data for a rpc quick print.
     * If the print succeeds, opens the resulting print URL in a new tab.
     *
     * @async
     * @returns {Promise<void>}
     * @throws Displays an error toast if the print process fails.
     */
    const print = async () => {
        try {
            setLoading(true);
            const center = map.getView().getCenter()!;
            const geojson = getPrintAreaGeojson(map);
            const layersConfig = getActiveLayerConfigurations();

            const pageInfo: PageInfo = {
                ...pageInfoRef.current,
                mapx: center[0],
                mapy: center[1],
                isIndex: false,
                orderNo: 1,
                scale: 500,
                themeLegendPosition: null,
                geojson,
            };

            const input: PrintArgs = {
                method: 'quick_print',
                id: 3,
                params: [
                    {
                        pageInfo: pageInfo,
                        layersConfig,
                        themeLegendConfig: [],
                        legendExtraData: {},
                        legendId: null,
                    },
                ],
            };

            const res = await quickPrint(input);
            if (res.result?.data && res.result.data !== null) {
                const printUrl =
                    buildTenantURL('/proxy/') + res.result.data.toString();
                closePrintMenus();

                window.open(printUrl, '_blank')?.focus();
            }
        } catch {
            toast.show(t('printError'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <StyledPrintMenuContainer>
            <StyledPrintMenuContainerHeader>
                <StyledPrintMenuContainerTitle>
                    {t('print')}
                </StyledPrintMenuContainerTitle>
                <StyledPrintMenuContainerTitleBtn
                    kind="ghost"
                    onClick={closePrintMenus}
                >
                    <Icons.Cross2 />
                </StyledPrintMenuContainerTitleBtn>
            </StyledPrintMenuContainerHeader>
            <StyledPrintMenuContainerContent>
                <StyledPrintMenuContainerTabsPrintBtn>
                    <StyledPrintMenuContainerTabs
                        options={[
                            { id: '1', label: t('quick') },
                            { id: '2', label: t('series') },
                        ]}
                        selected="1"
                    />
                    <Button
                        data-testid="printMenu-printBtn"
                        label={t('print')}
                        kind="primary"
                        onClick={print}
                        disabled={loading}
                    />
                </StyledPrintMenuContainerTabsPrintBtn>
                <StyledPrintMenuContainerSettings>
                    <PrintMenuSettingPart pageInfoRef={pageInfoRef} />
                    <PrintMenuLegendPart pageInfoRef={pageInfoRef} />
                    <PrintMenuThemeLegendPart />
                </StyledPrintMenuContainerSettings>
            </StyledPrintMenuContainerContent>
        </StyledPrintMenuContainer>
    );
};

const StyledPrintMenuContainer = styled(SideMenu)`
    width: auto;
    & > div:first-child {
        padding: 12px;
    }
    position: absolute;
    right: 0;
`;

const StyledPrintMenuContainerHeader = styled.div`
    display: flex;
    align-items: center;
`;

const StyledPrintMenuContainerTitle = styled.div`
    margin-right: auto;
    padding-left: 4px;
    padding-top: 6px;
    padding-bottom: 6px;
    ${(props) => props.theme.fonts['18px Bold']};
    color: ${(props) => props.theme.colors.neutral['100']};
`;

const StyledPrintMenuContainerTitleBtn = styled(Button)`
    padding: 0;
    width: 32px;
    color: ${(props) => props.theme.colors.neutral['90']};
    & > svg {
        width: 16px;
        height: 16px;
    }
`;

const StyledPrintMenuContainerContent = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding-left: 8px;
    padding-right: 8px;
`;

const StyledPrintMenuContainerTabsPrintBtn = styled.div`
    padding: 16px;
    display: flex;
    & > button {
        width: 108px;
    }
    gap: 12px;
`;

const StyledPrintMenuContainerTabs = styled(TabSelector)`
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    & > button {
        color: ${(props) => props.theme.colors.neutral['90']};
        font-weight: 700;
        line-height: 20px;
        width: 88px;
    }
`;

const StyledPrintMenuContainerSettings = styled.div`
    display: grid;
    gap: 8px;
`;
