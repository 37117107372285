import { SearchState } from '@stores';
import { StoreApi, UseBoundStore } from 'zustand';

/**
 * Toggles the selected object type in the search store.
 * @param searchStore The search store.
 * @param objectTypeValue The object type value.
 */
export const toggleSelectedObjectType = (
    searchStore: UseBoundStore<StoreApi<SearchState>>,
    objectTypeValue: string,
) => {
    searchStore.setState((state) => ({
        selectedObjectType:
            state.selectedObjectType === objectTypeValue ? '' : objectTypeValue,
        isUsingAdvancedSearch: false,
        selectedAdvancedFilters: {},
    }));
};
