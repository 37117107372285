import { FilterObject, toggleSelectedObjectType } from '@components';
import { objectTypes } from '@components/utils';
import {
    getTranslationKey,
    getTypeByModel,
    isModelSupported,
} from '@form-configs';
import { Button, Icons, styled, useIsMobile } from '@keypro/2nd-xp';
import { useMobileMenu, useSearchStore } from '@stores';
import { t } from 'i18next';

interface SearchFiltersProps {
    // handle the advancedSearchRef in apptoolbar
    advancedSearchRef?: React.MutableRefObject<{
        handleSubmit: (e: React.FormEvent, newFilters?: string[]) => void;
    } | null>;
}

export const SearchFilters = ({ advancedSearchRef }: SearchFiltersProps) => {
    const {
        selectedObjectType,
        selectedAdvancedFilters,
        setSelectedAdvancedFilters,
        isAdvancedSearchOpen,
        setIsAdvancedSearchOpen,
        isFullsearch,
        quickFilterComponent,
    } = useSearchStore();
    const isMobile = useIsMobile();
    const { setMenuHeight, storePreviousMenu } = useMobileMenu();

    const showAdvancedSearch = () => {
        setIsAdvancedSearchOpen(true);
        storePreviousMenu();
        setMenuHeight(100);
    };

    const handleClearAdvancedFilter = (
        key: string,
        selectedFilters: FilterObject,
        setFilters: (filters: FilterObject) => void,
        event: React.MouseEvent,
        handleSubmit?: (e: React.FormEvent, groups?: string[]) => void,
    ) => {
        event.stopPropagation();

        const updatedFilters = { ...selectedFilters };
        delete updatedFilters[key];
        setFilters(updatedFilters);

        const updatedGroups = Object.keys(updatedFilters).filter(
            (filterKey) => filterKey !== 'id',
        );

        if (handleSubmit) {
            handleSubmit(event, updatedGroups);
        }
    };

    const renderAdvancedSearchFilters = () => {
        if (!isModelSupported(selectedObjectType)) return;

        const filters = Object.entries(selectedAdvancedFilters);

        if (filters.length === 0 && quickFilterComponent) {
            return (
                <StyledQuickFilter $isMobile={isMobile}>
                    {quickFilterComponent}
                </StyledQuickFilter>
            );
        }

        if (!selectedObjectType) return null; // Skip rendering if selected type is 'All'

        return filters.map(([key]) => {
            if (key === 'id') return null; // Skip rendering if key is "id"

            const gqlType = getTypeByModel(selectedObjectType);
            const translationKey = getTranslationKey(gqlType, key);

            return (
                <StyledChip
                    key={key}
                    onClick={showAdvancedSearch}
                    data-testid={`advanced-search-filter-${key}`}
                    $isMobile={isMobile}
                >
                    {t(translationKey)}
                    <StyledChipClear
                        onClick={(e) =>
                            handleClearAdvancedFilter(
                                key,
                                selectedAdvancedFilters,
                                setSelectedAdvancedFilters,
                                e,
                                advancedSearchRef?.current?.handleSubmit,
                            )
                        }
                        data-testid={`clear-advanced-filter-${key}`}
                    >
                        <Icons.Clear />
                    </StyledChipClear>
                </StyledChip>
            );
        });
    };

    return (
        <>
            <SearchTypeFilters $isMobile={isMobile}>
                <SearchTypeFilterButton
                    key={'all'}
                    label={'All'}
                    $allBtn={true}
                    $isSelected={selectedObjectType === ''}
                    onClick={() => toggleSelectedObjectType(useSearchStore, '')}
                    data-testid="searchFilter-All"
                />
                {objectTypes.map(({ value, label }) => (
                    <SearchTypeFilterButton
                        key={value}
                        label={label}
                        $isSelected={selectedObjectType === value}
                        onClick={() =>
                            toggleSelectedObjectType(useSearchStore, value)
                        }
                        data-testid={`searchFilter-${label}`}
                    />
                ))}
            </SearchTypeFilters>
            {!isMobile && <StyledDivider />}
            {!isAdvancedSearchOpen && (isFullsearch || !isMobile) && (
                <StyledSearchFiltersOptions $isMobile={isMobile}>
                    <StyledButtonAddFilter
                        kind="secondary"
                        label={t('addFilter')}
                        data-tooltip={t('openAdvancedSearch')}
                        onClick={showAdvancedSearch}
                        data-testid="open-advanced-search-button"
                        $isMobile={isMobile}
                    />
                    <StyledAdvancedSearchFilters $isMobile={isMobile}>
                        {renderAdvancedSearchFilters()}
                    </StyledAdvancedSearchFilters>
                </StyledSearchFiltersOptions>
            )}
        </>
    );
};

/**
 * Container for type filter buttons.
 * @param $isMobile If the component is rendered in mobile mode.
 */
export const SearchTypeFilters = styled.div<{ $isMobile?: boolean }>`
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    & > button {
        border-radius: 28px;
        padding: 6px 10px;
        margin: 4px;
        background-color: ${(props) => props.theme.colors.neutral['50']};
    }
    ${(props) =>
        props.$isMobile &&
        `
        height: 44px;
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        padding-bottom: 0;
        border: 1px solid black;
        transition: height 250ms;
    `}
`;

/**
 * Type filter button.
 * @param $isSelected If the button is selected.
 * @param $allBtn If the button is the "All" button.
 * @param $isMobile If the component is rendered in mobile mode.
 */
export const SearchTypeFilterButton = styled(Button)<{
    $isSelected: boolean;
    $allBtn?: boolean;
    $isMobile?: boolean;
}>`
    ${(props) =>
        props.$isMobile
            ? props.theme.fonts['14px Regular']
            : props.theme.fonts['12px Regular']};
    color: ${(props) => props.theme.colors.neutral['90']};
    background-color: ${(props) =>
        props.$isSelected
            ? props.theme.colors.accents.blue['10']
            : props.theme.colors.neutral['50']} !important;
    width: ${(props) => (props.$allBtn ? '41px' : 'auto')};
`;

const StyledDivider = styled.div`
    height: 2px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.neutral['30']};
`;

const StyledSearchFiltersOptions = styled.div<{ $isMobile?: boolean }>`
    padding: 6px 16px 0px;
    ${(props) =>
        props.$isMobile &&
        `
        display: flex;
        flex-direction: row;
        padding-bottom: 4px;
        padding-top: 4px;
    `}
`;

const StyledButtonAddFilter = styled(Button)<{ $isMobile?: boolean }>`
    ${(props) => props.theme.fonts['12px Medium']};
    background-color: ${(props) => props.theme.colors.neutral['50']};
    height: 24px;
    margin-right: 8px;
    ${(props) =>
        props.$isMobile &&
        `
        margin-top: 4px;
    `}
`;

const StyledAdvancedSearchFilters = styled.div<{ $isMobile?: boolean }>`
    display: inline;
    padding: 8px 0px 8px 0px;
    ${(props) =>
        props.$isMobile &&
        `
        height: 32px;
        padding: 2px 0px;
    `}
`;

const StyledChip = styled.div<{ $isMobile?: boolean }>`
    display: inline-flex;
    align-items: center;
    gap: 4px;
    background-color: ${(props) => props.theme.colors.neutral['40']};
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 4px;
    padding: 4px 4px 4px 6px;
    color: ${(props) => props.theme.colors.neutral['90']};
    cursor: pointer;
    margin: 2px;
    ${(props) =>
        props.$isMobile &&
        `
        white-space: nowrap;
    `}
`;

const StyledChipClear = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.neutral['80']};
    & > svg {
        width: 14px;
        height: 14px;
    }
`;

const StyledQuickFilter = styled.div<{ $isMobile?: boolean }>`
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    padding: 2px 4px 2px 4px;
    cursor: pointer;
    height: 24px;
    & > div > div > div > div > button > span {
        margin-top: 12px;
        background-color: ${(props) => props.theme.colors.neutral['20']};

        & > svg:first-child {
            display: none;
        }
        & > svg {
            & path[stroke] {
                stroke: ${(props) => props.theme.colors.neutral['80']};
                stroke-width: 2px;
            }
        }
    }
    & > div > div > div > div > button > div {
        padding-right: 20px;
        color: ${(props) => props.theme.colors.neutral['90']};
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
    }
    ${(props) =>
        props.$isMobile &&
        `
        padding: 2px 0px;
        margin: 0px;
    `}
`;
