query getPlans($filter: PlanFilter) {
  getPlans(filter: $filter) {
    plans {
      id
      name
      description
      beginDate
      expireDate
      createdBy
      createdDate
      updatedBy
      updatedDate
      location
      type {id, txt}
      network
      state {id, txt}
    }
  }
}
