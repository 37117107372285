import { getTelecomAreas } from '@apis/keycom';
import { FormConfig } from '../types';
import { Icons } from '@keypro/2nd-xp';
import { createComboField } from '../field-utils';

const config: FormConfig = {
    gqlType: 'TelecomArea',
    model: 'telecomarea',
    icon: <Icons.Area />,
    groups: [
        {
            name: 'objectData',
            fields: [
                { name: 'exchangeArea', component: 'combobox' },
                { name: 'name', translationKey: 'telecomAreaName' },
                createComboField(
                    'telecomAreaType',
                    'TELECOM_AREA_TYPE',
                    false,
                    'type',
                ),
                { name: 'areaSize', translationKey: 'area' },
                'perimeter',
                { name: 'remarks', component: 'textarea' },
            ],
        },
    ],
    functions: {
        get: getTelecomAreas,
    },
    queryCollection: 'telecomAreas',
};

export default config;
