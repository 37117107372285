import { Icons, Input, Select, SelectOptionItem, styled } from '@keypro/2nd-xp';
import { PrintMenuSetting } from './PrintMenuSetting';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { PageInfo } from '@generated';
import { AuthContext } from '@providers';

interface PrintMenuLegendPartProps {
    pageInfoRef: React.MutableRefObject<PageInfo>;
}

export const PrintMenuLegendPart = ({
    pageInfoRef,
}: PrintMenuLegendPartProps) => {
    const [isDisableSetting, setIsDisableSetting] = useState(false);
    const { user } = useContext(AuthContext);
    const [createdBy, setCreatedBy] = useState(user?.email ?? '');
    const [remarks, setRemarks] = useState('');
    const [legendPosition, setLegendPosition] = useState('2');

    const legendPositionOptions: SelectOptionItem[] = [
        { label: t('topLeft'), value: '0' },
        { label: t('bottomLeft'), value: '1' },
        { label: t('bottomRight'), value: '2' },
        { label: t('topRight'), value: '3' },
    ];

    useEffect(() => {
        pageInfoRef.current = {
            ...pageInfoRef.current,
            createdBy: createdBy,
            remarks,
            legendPosition: isDisableSetting ? legendPosition : null,
        };
    }, [createdBy, remarks, isDisableSetting, legendPosition, pageInfoRef]);

    useEffect(() => {
        const userEmail = document.getElementById('printPreview-userEmail');
        if (userEmail && createdBy) userEmail.innerHTML = createdBy;
    }, [createdBy]);

    return (
        <PrintMenuSetting
            icon={<Icons.PrintLegend />}
            title={t('legend')}
            isOn={true}
            isDisableSetting={isDisableSetting}
            onChangeDisabled={() => setIsDisableSetting(!isDisableSetting)}
        >
            <StyledPrintMenuSettingTextField data-testid="printController-createBy">
                <Input
                    inputProps={{
                        placeholder: t('createdBy'),
                        defaultValue: user?.email,
                        onChange: (
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                            setCreatedBy(event.target.value);
                        },
                    }}
                    disabled={!isDisableSetting}
                />
            </StyledPrintMenuSettingTextField>
            <StyledPrintMenuSettingSelectField data-testid="printController-legendPosition">
                <StyledPrintMenuSettingSelectFieldLabel>
                    {t('legendPosition')}
                </StyledPrintMenuSettingSelectFieldLabel>
                <StyledSelect
                    isFilterable
                    options={legendPositionOptions}
                    defaultValue="2"
                    onChangeValue={(value) => {
                        if (typeof value === 'string') {
                            setLegendPosition(value);
                        }
                    }}
                    disabled={!isDisableSetting}
                />
            </StyledPrintMenuSettingSelectField>
            <StyledPrintMenuSettingTextField data-testid="printController-remarks">
                <Input
                    multiline={true}
                    inputProps={{
                        placeholder: t('addNote'),
                        onChange: (
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                            setRemarks(event.target.value);
                        },
                    }}
                    disabled={!isDisableSetting}
                />
            </StyledPrintMenuSettingTextField>
        </PrintMenuSetting>
    );
};

const StyledPrintMenuSettingTextField = styled.div`
    display: flex;
    & textarea {
        display: flex;
        box-sizing: border-box;
        height: 88px;
    }
`;

const StyledPrintMenuSettingSelectField = styled.div`
    display: flex;
    align-items: center;
`;

const StyledPrintMenuSettingSelectFieldLabel = styled.div`
    ${(props) => props.theme.fonts['14px Regular']};
    color: ${(props) => props.theme.colors.neutral['90']};
`;

const StyledSelect = styled(Select)`
    width: 185px;
    height: 32px;
    margin-left: auto;
`;
