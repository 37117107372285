import { SearchResult } from '@generated';
import { getTenant } from '@utils';
import { create } from 'zustand';

/** Maximum number of recent objects to store */
const MAX_RECENT_OBJECTS = 100;

/**
 * State for the recent objects store.
 */
interface RecentObjectsState {
    /** List of recently viewed objects */
    recentObjects: SearchResult[];
    /** Get the recent objects by model name */
    getRecentObjectsByModel: (model: string) => SearchResult[];
    /** Add a new object to the recent objects list */
    addRecentObject: (model: string, id: number, label: string) => void;
}

const useRecentObjectsStore = create<RecentObjectsState>((_set, get) => {
    const recentObjects = JSON.parse(
        localStorage.getItem(`recentObjects${getTenant(true)}`) ?? '[]',
    );

    return {
        recentObjects,
        getRecentObjectsByModel: (model: string) => {
            const { recentObjects: objects } = get();
            return objects.filter((object) => object.modelName === model);
        },
        addRecentObject: (model, id, label) => {
            const { recentObjects: objects } = get();

            // Remove previous duplicates by model and id
            objects
                .filter(
                    (object) => model === object.modelName && id === object.id,
                )
                .forEach((object) => {
                    objects.splice(objects.indexOf(object), 1);
                });

            objects.unshift({
                id: id,
                identification: label,
                modelName: model,
            });

            if (objects.length >= MAX_RECENT_OBJECTS) {
                objects.splice(MAX_RECENT_OBJECTS);
            }

            localStorage.setItem(
                `recentObjects${getTenant(true)}`,
                JSON.stringify(objects),
            );
        },
    };
});

/**
 * Hook to access the recent objects store.
 * @returns The recent objects and a function to add a new object to the store
 */
export const useRecentObjects = () => {
    const { recentObjects, getRecentObjectsByModel, addRecentObject } =
        useRecentObjectsStore();
    return { recentObjects, getRecentObjectsByModel, addRecentObject };
};
