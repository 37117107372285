import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useGetInfoTools } from '@hooks/map';
import {
    MapContext,
    ObjectPopup,
    ObjectPopupItem,
    PositionOverlay,
    Icons,
    styled,
    InfoToolSelectionEvent,
    InfoToolSelectionMode,
    ObjectDivider,
    NearbyObject,
    useIsMobile,
    Coordinates,
} from '@keypro/2nd-xp';
import { InfoTool, SearchArea } from '@generated';
import { ToggleObjectController } from './ToggleObjectController';
import { useTranslation } from 'react-i18next';
import {
    useCenterMenu,
    useLeftMenu,
    useMobileMenu,
    useRightMenu,
} from '@stores';
import { InfoObject } from '@components/InfoObject';
import {
    getFormConfig,
    getModelIcon,
    getTranslatedTitle,
    getTypeByModel,
    isEmpty,
    isModelSupported,
} from '@form-configs';
import { MapBrowserEvent } from 'ol';
import { pointToPolygon } from '@components';
import { Positioning } from 'ol/Overlay';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import { Pixel } from 'ol/pixel';
import { Coordinate as OlCoordinate } from 'ol/coordinate';

/**
 * The maximum number of results to display in a category before it can no longer be expanded.
 */
const EXPANDABLE_LIMIT = 20;

/**
 * The maximum number of results to display in a category before it's no longer expanded by default.
 */
const EXPANDED_BY_DEFAULT_LIMIT = 10;

/**
 * The time in milliseconds before hover info request is sent.
 */
const HOVER_TIME = 250;

/**
 * The maximum display scale to enable hovering functionality (in cm).
 */
const MAX_HOVER_DISPLAY_SCALE = 2000;

/**
 * Display scale based multiplier used for calculating the search area around the point.
 */
const POINT_DISPLAY_SCALE_MULTIPLIER = 0.005;

/**
 * Info tool result groups.
 */
interface InfoToolGroups {
    [key: string]: InfoToolGroup;
}

interface AvailableSpace {
    x: number;
    y: number;
    width: number;
    height: number;
}

type Coordinate = [number, number];

/**
 * Info tool result group.
 */
interface InfoToolGroup {
    isExpanded: boolean;
    allowExpand: boolean;
    results: InfoTool[];
}

/**
 * The ObjectOverlay component displays content on top of the map.
 * @returns The ObjectOverlay component
 */
export const ObjectOverlay = () => {
    const isMobile = useIsMobile();
    let setMenuContent;
    let infoToolSelectionMode;
    let isMenuOpen;
    let detailedMenu;
    const {
        setMenuContent: setMobileMenuContent,
        infoToolSelectionMode: mobileInfoToolSelectionMode,
        isMenuOpen: isMobileMenuOpen,
        detailedMenu: detailedMobileMenu,
        menuHeight,
    } = useMobileMenu();

    const { setMenuContent: setRightMenuContent, isMenuOpen: isRightMenuOpen } =
        useRightMenu();
    const { infoToolSelectionMode: centerInfoToolSelectionMode } =
        useCenterMenu();
    const { isMenuOpen: isLeftMenuOpen, detailedMenu: detailedLeftMenu } =
        useLeftMenu();

    if (isMobile) {
        setMenuContent = setMobileMenuContent;
        infoToolSelectionMode = mobileInfoToolSelectionMode;
        isMenuOpen = isMobileMenuOpen;
        detailedMenu = detailedMobileMenu;
    } else {
        setMenuContent = setRightMenuContent;
        infoToolSelectionMode = centerInfoToolSelectionMode;
        isMenuOpen = isLeftMenuOpen;
        detailedMenu = detailedLeftMenu;
    }

    const { t } = useTranslation();
    const controller = useContext(MapContext)!;

    const [position, setPosition] = useState<Coordinate | undefined>([0, 0]);
    const [offset, setOffset] = useState<number>(0);
    const [positioning, setPositioning] = useState<Positioning | undefined>();
    const [visibility, setVisibility] = useState<boolean>(false);
    const popupRef = useRef<HTMLDivElement>(null);

    const [infoToolFilter, setInfoToolFilter] = useState<
        | {
              layers: string[];
              searchArea: SearchArea;
          }
        | undefined
    >();

    const infoTools = useGetInfoTools({
        layers: infoToolFilter?.layers,
        searchArea: infoToolFilter?.searchArea,
    });

    const headerResult = useMemo(() => {
        return infoTools.data?.results?.[0];
    }, [infoTools.data]);

    const isInMapView =
        !!headerResult &&
        infoToolSelectionMode === InfoToolSelectionMode.MAP_VIEW;

    const hoverTimeoutId = useRef<number>(0);
    const usingInfoTool = useRef<boolean>(false);

    const { shouldDisplayInfoToolGroups, infoToolGroups } = useMemo(() => {
        const infoToolGroups: InfoToolGroups = {};

        const shouldDisplayInfoToolGroups =
            infoTools.data?.results && infoTools.data?.results?.length > 1;

        if (shouldDisplayInfoToolGroups && infoTools.data) {
            const results = infoTools.data.results.slice(1);

            const models = Array.from(
                new Set(results.map((result) => result.model)),
            );

            models.forEach((model) => {
                if (!model) return;

                const modelResults = results.filter(
                    (result) => result.model === model,
                );

                infoToolGroups[model] = {
                    allowExpand: modelResults.length <= EXPANDABLE_LIMIT,
                    isExpanded:
                        modelResults.length <= EXPANDED_BY_DEFAULT_LIMIT,
                    results: modelResults,
                };
            });
        }

        return {
            shouldDisplayInfoToolGroups,
            infoToolGroups,
        };
    }, [infoTools.data]);

    const getAvailableMapSpace = useCallback(() => {
        if (isMobile) {
            const centerMenu = document.getElementById('root')!;
            const { x, y, width, height } = centerMenu.getBoundingClientRect();
            const availableMapSpace = { x, y, width, height };

            if (isMenuOpen) {
                availableMapSpace.height =
                    (availableMapSpace.height * (100 - menuHeight)) / 100;
            }
            return availableMapSpace;
        }

        const centerMenu = document.getElementById('center-menu')!;
        const { x, y, width, height } = centerMenu.getBoundingClientRect();
        const availableMapSpace = { x, y, width, height };

        if (!isRightMenuOpen) {
            const rightMenu = document.getElementById('right-menu')!;
            const { width: rightMenuWidth } = rightMenu.getBoundingClientRect();
            availableMapSpace.width += rightMenuWidth;
        }

        return availableMapSpace;
    }, [isMobile, isMenuOpen, menuHeight, isRightMenuOpen]);

    const setPositionAndPositioning = useCallback(
        (coordInPixel: Pixel, coord: Coordinate, retry = 0) => {
            setVisibility(false);
            const { x, y, width, height } = getAvailableMapSpace();
            const availableWidth = x + width;
            const availableHeight = y + height;

            const requiredWidth =
                shouldDisplayInfoToolGroups && infoToolGroups ? 324 : 280;

            let newPosition: Positioning = 'top-left';
            if (coordInPixel[0] + requiredWidth > availableWidth) {
                newPosition = 'top-right';
            }

            const currentPopup = popupRef.current;

            if (!currentPopup) {
                if (retry < 5) {
                    // Popup not ready, retrying...
                    setTimeout(
                        () =>
                            setPositionAndPositioning(
                                coordInPixel,
                                coord,
                                retry + 1,
                            ),
                        50,
                    );
                }
                return;
            }

            const rect = currentPopup.getBoundingClientRect();
            if (coordInPixel[1] + rect.height > availableHeight - 55) {
                if (newPosition === 'top-left') newPosition = 'bottom-left';
                else newPosition = 'bottom-right';
            }

            setVisibility(true);
            usingInfoTool.current = false;
            setPositioning(newPosition);
            setPosition(coord);
        },
        [
            shouldDisplayInfoToolGroups,
            infoToolGroups,
            setVisibility,
            setPositioning,
            setPosition,
            popupRef,
            usingInfoTool,
            getAvailableMapSpace,
        ],
    );

    // Handle popup position when using infoTool
    useEffect(() => {
        const isPixelInSpace = (
            pixel: Pixel,
            { x, y, width, height }: AvailableSpace,
        ) => {
            return (
                pixel[0] + x >= 0 &&
                pixel[1] + y >= 0 &&
                pixel[0] < width &&
                pixel[1] < height
            );
        };

        const handlePositionAndPositioning = (input: string) => {
            if (isInMapView) return;

            const geom = controller.wktToGeometry(input) as SimpleGeometry;
            if (!geom) return;

            const availableMapSpace = getAvailableMapSpace();

            const extent = geom.getExtent();
            const center: Coordinate = [
                (extent[0] + extent[2]) / 2,
                (extent[1] + extent[3]) / 2,
            ];
            const centerInPixel = controller.map.getPixelFromCoordinate(center);

            // If center is in the available map space
            if (isPixelInSpace(centerInPixel, availableMapSpace)) {
                setPositionAndPositioning(centerInPixel, center);
                return;
            }

            // If suitable vertices are in the available map space
            const vertices = geom.getCoordinates()!;
            let verticesArray: [OlCoordinate];

            // When getCoordinates() call returns [[OlCoordinate]]
            if (vertices.length === 1) verticesArray = vertices[0];
            // When getCoordinates() call returns OlCoordinate
            else if (vertices.every((ele) => typeof ele === 'number'))
                verticesArray = [vertices];
            // When getCoordinates() call returns [OlCoordinate]
            else verticesArray = vertices as [OlCoordinate];

            const verticesInPixel = verticesArray.map(([x, y]) =>
                controller.map.getPixelFromCoordinate([x, y]),
            );

            const suitablePixel = verticesInPixel.find((pixel) =>
                isPixelInSpace(pixel, availableMapSpace),
            );

            if (suitablePixel) {
                const suitableVertex =
                    controller.map.getCoordinateFromPixel(suitablePixel);
                setPositionAndPositioning(
                    suitablePixel,
                    suitableVertex as Coordinate,
                );
                return;
            }

            const { y, width, height } = availableMapSpace;
            const availableCenter: Coordinate = [width / 2, (y + height) / 2];
            const centerCoord =
                controller.map.getCoordinateFromPixel(availableCenter);
            const closestPoint = geom.getClosestPoint(centerCoord);
            const closestPointInPixel =
                controller.map.getPixelFromCoordinate(closestPoint);

            if (isPixelInSpace(closestPointInPixel, availableMapSpace)) {
                // Selected an edge
                setPositionAndPositioning(
                    closestPointInPixel,
                    closestPoint as Coordinate,
                );
                return;
            } else {
                // Zoomed in too much or the polygon is too big
                setPositionAndPositioning(
                    availableCenter,
                    centerCoord as Coordinate,
                );
            }
        };

        headerResult?.location &&
            usingInfoTool.current &&
            handlePositionAndPositioning(headerResult.location);
    }, [
        headerResult,
        controller,
        isInMapView,
        positioning,
        infoToolGroups,
        isRightMenuOpen,
        shouldDisplayInfoToolGroups,
        isMenuOpen,
        menuHeight,
        isMobile,
        usingInfoTool,
        getAvailableMapSpace,
        setPositionAndPositioning,
    ]);

    useEffect(() => {
        const handleInfoToolSelection = (event: InfoToolSelectionEvent) => {
            usingInfoTool.current = true;
            setInfoToolFilter({
                layers: event.layers,
                searchArea: event.feature,
            });
        };

        controller.on('infoToolSelection', handleInfoToolSelection);

        return () => {
            controller.off('infoToolSelection', handleInfoToolSelection);
        };
    }, [controller]);

    // Handle popup position when click or hover
    useEffect(() => {
        let isCursorOverMap = false;

        const setFilter = (layers: string[], coordinates: Coordinates) => {
            const size =
                controller.displayScale * POINT_DISPLAY_SCALE_MULTIPLIER;
            const polygon = pointToPolygon(coordinates, size);

            setInfoToolFilter({
                layers: layers,
                searchArea: {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: polygon.getCoordinates(),
                    },
                    properties: null,
                },
            });

            const isUsingInfoTool = usingInfoTool.current;
            if (isUsingInfoTool) return;

            const coordinatesInPixel =
                controller.map.getPixelFromCoordinate(coordinates);
            setPositionAndPositioning(coordinatesInPixel, coordinates);
        };

        const onMouseClick = (event: MapBrowserEvent<UIEvent>) => {
            const activeLayerNames = controller.layers.getActiveLayerNames();
            const coordinates: Coordinate = [
                event.coordinate[0],
                event.coordinate[1],
            ];

            setFilter(activeLayerNames, coordinates);
        };

        const onMouseMove = (event: MapBrowserEvent<UIEvent>) => {
            if (hoverTimeoutId.current) {
                clearTimeout(hoverTimeoutId.current);
            }

            if (
                isCursorOverMap &&
                controller.displayScale <= MAX_HOVER_DISPLAY_SCALE
            ) {
                hoverTimeoutId.current = window.setTimeout(() => {
                    const activeLayerNames =
                        controller.layers.getActiveLayerNames();
                    const coordinates: Coordinate = [
                        event.coordinate[0],
                        event.coordinate[1],
                    ];

                    setFilter(activeLayerNames, coordinates);
                }, HOVER_TIME);
            }
        };

        const onDocumentMouseOver = (event: MouseEvent) => {
            isCursorOverMap = event.target === document.querySelector('canvas');
        };

        controller.on('mouseClick', onMouseClick);
        controller.on('mouseMove', onMouseMove);
        document.addEventListener('mouseover', onDocumentMouseOver);

        return () => {
            controller.off('mouseClick', onMouseClick);
            controller.off('mouseMove', onMouseMove);
            document.removeEventListener('mouseover', onDocumentMouseOver);

            if (hoverTimeoutId.current) {
                clearTimeout(hoverTimeoutId.current);
            }
        };
    }, [controller, setPositionAndPositioning]);

    const renderInfoToolGroups = () => {
        if (shouldDisplayInfoToolGroups && infoToolGroups) {
            const list = Object.entries(infoToolGroups);
            return (
                <>
                    <ObjectDivider />
                    <NearbyObject
                        title={t('nearbyObjects') + ':'}
                        zoomBtnLabel={t('zoomToAll')}
                        browseBtnLabel={t('browseAll')}
                        zoomBtnIcon={<Icons.ZoomIn />}
                        browseBtnIcon={<Icons.Net />}
                    />
                    {list.map(([key, value], index) => {
                        return (
                            <React.Fragment key={key}>
                                <ToggleObjectController
                                    model={key}
                                    value={value.results}
                                    shouldExpandable={value.allowExpand}
                                    isExpandedByDefault={value.isExpanded}
                                />
                                {index <
                                    Object.keys(infoToolGroups).length - 1 && (
                                    <ObjectDivider />
                                )}
                            </React.Fragment>
                        );
                    })}
                </>
            );
        }
    };

    useEffect(() => {
        let offSetX = 16;
        const navigationBar = document
            .getElementById('navigation-bar')
            ?.getBoundingClientRect();
        offSetX = offSetX + (navigationBar?.width ?? 0);

        if (isMenuOpen || detailedMenu) {
            const leftMenuRect = document
                .getElementById('left-menu')
                ?.getBoundingClientRect();
            offSetX = offSetX + (leftMenuRect?.width ?? 0);
        }
        setOffset(offSetX);
    }, [isInMapView, isMenuOpen, detailedMenu]);

    const gqlType =
        headerResult?.model && isModelSupported(headerResult.model)
            ? getTypeByModel(headerResult.model)
            : null;
    const form = gqlType ? getFormConfig(gqlType) : null;
    const isSupported = gqlType && form;

    const renderObjectPopup = () => (
        <>
            {headerResult && (
                <ObjectPopup data-testid="object-popup">
                    <ObjectPopupItem
                        title={
                            isSupported
                                ? getTranslatedTitle(gqlType)
                                : (headerResult.model ?? '')
                        }
                        tagText={
                            isEmpty(headerResult.identification)
                                ? (headerResult.pk ?? '')
                                : headerResult.identification
                        }
                        iconObject={form?.icon ?? getModelIcon()}
                        more={<Icons.More />}
                        zoom={<Icons.ZoomIn />}
                        data-testid="object-popup-item"
                        onClickAction={() => {
                            setMenuContent(
                                `InfoObject-${headerResult.model}-${headerResult.pk}`,
                                <InfoObject
                                    model={headerResult.model!}
                                    id={headerResult.pk!}
                                />,
                            );
                        }}
                    />
                    {renderInfoToolGroups()}
                </ObjectPopup>
            )}
        </>
    );

    return (
        <>
            <PositionOverlay position={position} positioning={positioning}>
                {!isInMapView && (
                    <div
                        ref={popupRef}
                        style={{
                            visibility: visibility ? 'visible' : 'hidden',
                            position: visibility ? undefined : 'absolute',
                        }}
                        data-testid={`object-popup-positioning-${positioning}`}
                    >
                        {renderObjectPopup()}
                    </div>
                )}
            </PositionOverlay>
            {isInMapView && (
                <StyledFixedObjectPopup $offset={offset}>
                    {renderObjectPopup()}
                </StyledFixedObjectPopup>
            )}
        </>
    );
};

const StyledFixedObjectPopup = styled.div<{
    $offset: number;
}>`
    position: absolute;
    width: 300px;
    top: 16px;
    left: ${(props) => props.$offset}px;
`;
