import { getPoles } from '@apis/keycom';
import { FormConfig } from '../types';
import { commonGroup, planGroup, telecomAreaGroup } from '../groups';
import { Icons } from '@keypro/2nd-xp';
import { createComboField } from '../field-utils';

const config: FormConfig = {
    gqlType: 'Pole',
    model: 'pole',
    icon: <Icons.Fat />,
    groups: [
        {
            name: 'objectData',
            fields: [
                createComboField('owner', 'POLE_OWNER'),
                createComboField('renter', 'POLE_OWNER'),
                'linePart',
                'poleNumber',
                'branchPoleNumber',
                'installYear',
                { name: 'checkoutDate', component: 'date' },
                'length',
                createComboField('stanchion', 'POLE_STANCHION'),
                createComboField('poleType', 'POLE_TYPE', false, 'type'),
                createComboField('usageState', 'POLE_STATE'),
                createComboField('material', 'POLE_MATERIAL'),
                createComboField('impregnant', 'POLE_IMPREGNANT'),
                createComboField('decayClass', 'POLE_DECAY_CLASS'),
                createComboField(
                    'stationDecayClass',
                    'POLE_DECAY_CLASS',
                    false,
                    'stanchionDecayClass',
                ),
                {
                    name: 'remarks',
                    component: 'textarea',
                },
            ],
        },
        telecomAreaGroup,
        planGroup,
        commonGroup,
    ],
    functions: {
        get: getPoles,
    },
    queryCollection: 'poles',
};

export default config;
