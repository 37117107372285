import { getCables } from '@apis/keycom';
import { FormConfig } from '../types';
import { commonGroup, planGroup, telecomAreaGroup } from '../groups';
import { Icons } from '@keypro/2nd-xp';
import { createComboField } from '../field-utils';

const config: FormConfig = {
    gqlType: 'Cable',
    model: 'cable',
    icon: <Icons.Cable />,
    groups: [
        {
            name: 'objectData',
            fields: [
                createComboField('owner', 'CABLE_OWNER'),
                createComboField('renter', 'CABLE_OWNER'),
                'identification',
                createComboField('state', 'MANHOLE_STATE'),
                'length',
                'extraLength',
                'totalLength',
                'installYear',
                'startReading',
                'endReading',
                createComboField('measure', 'CABLE_MEASURE'),
                createComboField('installationType', 'CABLE_TYPE'),
                createComboField('technology', 'CABLE_TECHNOLOGY'),
                createComboField('role', 'CABLE_ROLE'),
                'batch',
                'importance',
                'cableId',
                {
                    name: 'remarks',
                    component: 'textarea',
                },
            ],
        },
        telecomAreaGroup,
        planGroup,
        {
            name: 'common',
            fields: [
                ...commonGroup.fields,
                createComboField('safetyDistance', 'SAFETY_DISTANCE'),
            ],
        },
    ],
    excludeFromSearch: [
        'telecomArea',
        'telecomAreaBind',
        'plan',
        'planIncludeCompleted',
        'historyDate',
        'cableId',
    ],
    functions: {
        get: getCables,
    },
    queryCollection: 'cables',
};

export default config;
