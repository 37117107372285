import { FormConfig } from '../types';
import { planGroup } from '../groups';
import { getPoints } from '@apis/keycore';
import { Icons } from '@keypro/2nd-xp';
import { createComboField } from '../field-utils';

const config: FormConfig = {
    gqlType: 'Point',
    model: 'point',
    icon: <Icons.AddressPoint />,
    groups: [
        {
            name: 'objectData',
            fields: [
                createComboField('owner', 'POINT_OBJECT_OWNER'),
                createComboField('renter', 'POINT_OBJECT_RENTER'),
                'identification',
                { name: 'description', translationKey: 'pointDescription' },
                'length',
                'installYear',
                'distance',
                createComboField('state', 'POINT_STATE'),
            ],
        },
        planGroup,
        {
            name: 'common',
            fields: [
                {
                    name: 'locationAccuracy',
                    filter: {
                        groupname: { eq: 'LOCATION_ACCURACY' },
                    },
                },
                {
                    name: 'haccuracy',
                    translationKey: 'heightAccuracy',
                    filter: {
                        groupname: { eq: 'H_ACCURACY' },
                    },
                },
                'historyDate',
                {
                    name: 'mappingMethod',
                    filter: {
                        groupname: { eq: 'MAPPING_METHOD' },
                    },
                },
                'surveyAmount',
                'mapper',
            ],
        },
    ],
    functions: {
        get: getPoints,
    },
    queryCollection: 'points',
};

export default config;
